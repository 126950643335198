import './styles.scss'
import { React, useEffect, useState } from 'react'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { useTranslation } from 'react-i18next'
import { commonMethods, useResizeHandler } from 'Utils/commonMethods'
import { REG_EX } from 'Utils/Constants'

const AccordionPopUp = ({ isDialogModalOpen, handleCloseModal, globalDifferentialHistoryData, mockIsWebView }) => {
  const totalSlides = globalDifferentialHistoryData?.length

  const { t } = useTranslation()

  const [slideIndex, setSlideIndex] = useState(0)
  const [currentSlideData, setCurrentSlideData] = useState(null)

  const { isWebView } = useResizeHandler()
  const { dateFormatBasedOnUser } = commonMethods

  useEffect(() => {
    setCurrentSlideData(globalDifferentialHistoryData[0])
  }, [globalDifferentialHistoryData])

  useEffect(() => {
    document.body.style.overflow = isDialogModalOpen ? 'hidden' : ''

    return () => {
      document.body.style.overflow = ''
    }
  }, [isDialogModalOpen])

  const switchSlide = (type) => {
    if (type === 'forword') {
      const forwordIndex = slideIndex === totalSlides - 1 ? 0 : slideIndex + 1

      setSlideIndex(forwordIndex)
      setCurrentSlideData(globalDifferentialHistoryData[forwordIndex])
    } else {
      const reverseIndex = slideIndex === 0 ? totalSlides - 1 : slideIndex - 1

      setSlideIndex(reverseIndex)
      setCurrentSlideData(globalDifferentialHistoryData[reverseIndex])
    }
  }

  const currencyStyle = (value) => {
    if (value) {
      const convertedValue = parseFloat(value?.replace(/[$¢]/g, ''))

      return {
        color: convertedValue < 0 ? 'red' : 'green',
        fontWeight: 600
      }
    }

    return { color: 'black' }
  }

  const globalDifferentialsModalContent = () => (
    <div className="global-differential-modal" data-testid="global-differential-modal">
      <div className="modal-body-style" data-testid="modal-body-style">
        <div>
          {totalSlides !== 1 ? (
            <div className="left-arrow" onClick={() => switchSlide('reverse')}>
              <i className="pi pi-angle-double-right angle-double-icon" />
            </div>
          ) : (
            <div className="empty-block"></div>
          )}
        </div>
        <div className="slide-wrapper" data-testid="slide-wrapper-container">
          <div className="slide-content" data-testid="slide-content-container">
            {currentSlideData?.label === 'Previous Data' && (
              <div className="history-data-slide" data-testid="history-data-table">
                {isWebView && (
                  <div className="history-table-table-wparrer">
                    <table>
                      <thead className="history-table-thead" data-testid="history-data-table-thead">
                        <tr>
                          <th colSpan={2}>
                            {t('COUNTRY')} : {currentSlideData?.country} <img src={currentSlideData?.flagUrl} alt="Flag" title="country-flag" />
                          </th>
                          <th colSpan={4} style={{ marginLeft: '0px' }}>
                            <span>
                              {t('GRADE')} : {currentSlideData?.qualityName}
                            </span>
                          </th>
                          <th>{t('FOREX_LEVEL')}</th>
                        </tr>
                        <tr>
                          <th>{t('DATE')}</th>
                          <th>{t('CROP_YEAR')}</th>
                          <th>{t('TERMINAL')}</th>
                          <th>{t('TERMINAL_LEVEL')}</th>
                          <th colSpan={2}>
                            {t('DIFFERENTIALS')} {t('BETWEEN')}
                          </th>
                          <th>{currentSlideData?.forexType}</th>
                        </tr>
                        <div className="empty_div">{/* This is needed for table heading overlap */}</div>
                      </thead>
                      <tbody>
                        {currentSlideData?.data?.map(({ uploadedDtm, cropYear, terminal, terminalLevel, rangeStart, rangeEnd, forexValue }, index) => {
                          const rangeStartMatch = rangeStart.match(/(-?)([^0-9]*)([0-9]+)/)
                          const [, rangeStartsign, rangeStartCurrencySymbol, rangeStartValue] = rangeStartMatch
                          const rangeEndMatch = rangeEnd.match(/(-?)([^0-9]*)([0-9]+)/)
                          const [, rangeEndsign, rangeEndCurrencySymbol, rangeEndValue] = rangeEndMatch

                          return (
                            <tr key={index}>
                              <td data-testid="uploaded-date-container">{dateFormatBasedOnUser(uploadedDtm) || '-'}</td>
                              <td data-testid="crop-year-container">{cropYear || '-'}</td>
                              <td data-testid="terminal-container">{terminal || '-'}</td>
                              <td data-testid="terminal-level-container">{terminalLevel || '-'}</td>
                              <td style={currencyStyle(rangeStart, currentSlideData?.data[index + 1]?.rangeStart)}>
                                <div className="currency-and-value" data-testid="range-start-value">
                                  <div className={`${rangeStartCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`} data-testid="start-value-symbol">
                                    {rangeStartsign} {rangeStartCurrencySymbol}
                                  </div>
                                  <div className="value" data-testid="start-value">
                                    {rangeStartValue || '-'}
                                  </div>
                                </div>
                              </td>
                              <td style={currencyStyle(rangeEnd, currentSlideData?.data[index + 1]?.rangeEnd)}>
                                <div className="currency-and-value" data-testid="range-end-value">
                                  <div className={`${rangeEndCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`} data-testid="end-value-symbol">
                                    {rangeEndsign} {rangeEndCurrencySymbol}
                                  </div>
                                  <div className="value" data-testid="end-value">
                                    {rangeEndValue || '-'}
                                  </div>
                                </div>
                              </td>
                              <td className="remark-cell" data-testid="remark-cell">
                                {forexValue || '-'}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                {(!isWebView || mockIsWebView) && (
                  <div className="mobile-view" data-testid="mobile-view-container">
                    <div className="popup-header" data-testid="mobile-view-header">
                      <div>
                        {t('COUNTRY')} : {currentSlideData?.country} <img src={currentSlideData?.flagUrl} alt="Flag" title="mobile-view-country-flag" />
                      </div>
                      <div>
                        {t('GRADE')} : {currentSlideData?.qualityName}
                      </div>
                    </div>
                    <div className="history-data-cards" data-testid="mobile-view-history-data-cards">
                      {currentSlideData?.data?.map(({ uploadedDtm, cropYear, terminal, terminalLevel, rangeStart, rangeEnd, remarks, forexValue }, index) => {
                        const rangeStartMatch = rangeStart.match(REG_EX.NEGATIVE_SIGN_SPACE_REGEX)
                        const [, rangeStartsign, rangeStartCurrencySymbol, rangeStartValue] = rangeStartMatch
                        const rangeEndMatch = rangeEnd.match(REG_EX.NEGATIVE_SIGN_SPACE_REGEX)
                        const [, rangeEndsign, rangeEndCurrencySymbol, rangeEndValue] = rangeEndMatch

                        return (
                          <div className="each-card" key={index}>
                            <div className="each-row" data-testid="each-row">
                              <span>{t('DATE')}: </span>
                              <div>{dateFormatBasedOnUser(uploadedDtm) || '-'}</div>
                            </div>
                            <div className="each-row">
                              <span>{t('CROP_YEAR')}: </span>
                              <div>{cropYear || '-'}</div>
                            </div>
                            <div className="each-row">
                              <span>{t('TERMINAL')}: </span>
                              <div>{terminal || '-'}</div>
                            </div>
                            <div className="each-row">
                              <span>{t('TERMINAL_LEVEL')}:</span>
                              <div>{terminalLevel || '-'}</div>
                            </div>
                            <div className="each-row">
                              <span>
                                {t('DIFFERENTIALS')} {t('BETWEEN')} {t('RANGE_STARTS')} :
                              </span>
                              <div className="differentil-between">
                                <div style={currencyStyle(rangeStart, currentSlideData?.data[index + 1]?.rangeStart)}>
                                  <div className="currency-and-value">
                                    <div className={`${rangeStartCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`}>
                                      {rangeStartsign} {rangeStartCurrencySymbol}
                                    </div>
                                    <div className="value">{rangeStartValue || '-'}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="each-row">
                              <span>
                                {t('DIFFERENTIALS')} {t('BETWEEN')} {t('RANGE_ENDS')} :
                              </span>
                              <div className="differentil-between">
                                <div style={currencyStyle(rangeEnd, currentSlideData?.data[index + 1]?.rangeEnd)}>
                                  <div className="currency-and-value">
                                    <div className={`${rangeEndCurrencySymbol === '¢' ? 'symbol cent-symbol' : 'symbol'}`}>
                                      {rangeEndsign} {rangeEndCurrencySymbol}
                                    </div>
                                    <div className="value">{rangeEndValue || '-'}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="each-row">
                              <span>{t('DELIVERY_PORT')}:</span>
                              <div>{remarks || '-'}</div>
                            </div>
                            <div className="each-row">
                              <span>{t('FOREX_LEVEL')}:</span>
                              <div>
                                {currentSlideData?.forexType} : {forexValue}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* This below data is for Future reference */}
            {/* {currentSlideData?.label === 'Slide One' && (
              <div className="history-data-slide">
                <table className="history-table-header">
                  <thead>
                    <tr>
                      <th className="country-name-th">
                        {t('COUNTRY')} : {currentSlideData?.country} <img src={currentSlideData?.flagUrl} alt="Flag" />
                      </th>
                      <th className="crop-year-th">{t('CROP_YEAR')} : 2030</th>
                      <th colSpan={2}>{currentSlideData?.qualityName}</th>
                    </tr>
                    <tr className="thead-rows">
                      <th className="date-cell">{t('DATE')}</th>
                      <th className="terminal-cell">{t('TERMINAL')}</th>
                      <th className="range-starts-cell" colSpan={2}>
                        {t('DIFFERENTIALS')} {t('BETWEEN')}
                      </th>
                    </tr>
                  </thead>
                </table>
                <table>
                  <tbody>
                    {currentSlideData?.data?.map(({ uploadedDtm, terminal, rangeStart, rangeEnd }, index) => (
                      <tr key={index}>
                        <td className="date-cell">{uploadedDtm}</td>
                        <td className="terminal-cell">{terminal || '-'}</td>
                        <td className="range-starts-cell">{rangeStart}</td>
                        <td className="range-ends-cell">{rangeEnd}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )} */}
          </div>
        </div>
        <div>
          {totalSlides !== 1 ? (
            <div className="right-arrow" onClick={() => switchSlide('forword')}>
              <i className="pi pi-angle-double-left angle-double-icon" />
            </div>
          ) : (
            <div className="empty-block"></div>
          )}
        </div>
      </div>
    </div>
  )

  return <DialogUIModal title={t('PREVIOUS_DATA')} titlePosition="center" size="lg" showModal={isDialogModalOpen} bodyContent={globalDifferentialsModalContent} handleClose={() => handleCloseModal()} testId="accordion-popup" />
}

export default AccordionPopUp
