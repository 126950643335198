/*eslint-disable*/
import { configUrl } from './configuration/index'
import coffeeWebApiService from './apiServiceCoffeeWeb'

const apiAdapterCoffeeWeb = {
  // AppSetting
  appSetting(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/AppSetting`, null, callback)
  },
  // About US
  getCountryTimeZone({ userId, pageNumber, pageSize }, generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/miscmaster/GetCountryTimeZone/${userId}/${pageNumber}/${pageSize}`, generatedToken, callback)
  },

  getFavoriteCountryClock({ userId }, generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/miscmaster/GetUserFavouriteTimeZone/${userId}`, generatedToken, callback)
  },

  makeFavoriteCountryClock(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/miscmaster/MakeUserTimeZoneFavourite`, null, body, callback)
  },

  // CheckMobileNumber
  checkMobileNumber(phoneNo, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/CheckMobileNumber/getvalidmobilenumbertovalidate/${phoneNo}`, null, callback)
  },

  // CoffeeNews
  getNewsById({ userId, newsId, isRead }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/getnewsbyid/${userId}/${newsId}/${isRead}`, null, callback)
  },

  getNewsAndMediaById(newsId, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetNewsAndMediaById/${newsId}`, null, callback)
  },

  postNewsUsersFavorite(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/news/PostNewsUsersFavourite`, null, body, callback)
  },

  makeNewsUnfavorite(newsId, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/news/MakeNewsUnfavourite/${newsId}`, null, null, callback)
  },

  getAllShortNewsForSubForCountry(userId, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetAllShortNewsForSubForCountry/${userId}`, null, callback)
  },

  getAllFreeTrendingNewsForGuest(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetAllFreeTrendingNews`, null, callback)
  },

  updateNewsViewCountOnUser({ NewsId, userid }, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/news/updateNewsViewCountOnUser/${NewsId}/${userid}`, null, null, callback)
  },

  getFavoriteNewsById({ userId, newsId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetFavouriteNewsById/${userId}/${newsId}`, null, callback)
  },

  convertToBase64(generatedToken, previewpdf, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/ConvertToBase64?filePath=${previewpdf}`, generatedToken, callback)
  },

  getMyFavoriteNewsForUserLanguage({ userId, pNumber, pSize, language }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetMyFavouriteNewsForUserLanguage/${userId}/${pNumber}/${pSize}/${language}`, null, callback)
  },

  getNewsByEncryptedId(sharednewsid, generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetNewsByEncryptedId/${sharednewsid}`, generatedToken, callback)
  },

  searchNews({ userId, searchValue, pNumber, pSize, active, languageId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/SearchNews/${userId}/${searchValue}/${pNumber}/${pSize}/${active}?languageid=${languageId}`, null, callback)
  },

  getNewsToDisplayForUserWithLock({ userId, pNumber, pSize, languageId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetNewsToDisplayForUserWithLock/${userId}/${pNumber}/${pSize}/${languageId}`, null, callback)
  },

  getNewsByEncryptedUserId({ newsId, userid, MarkAsNotificationRead }, generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetNewsByEncryptedUserId/${newsId}/${userid}/${MarkAsNotificationRead}`, generatedToken, callback)
  },

  getAllFreeNewsByLanguage({ languageId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetAllFreeNewsToDisplay/${languageId}`, null, callback)
  },

  getFreeNewsToDisplayForUser({ pageNumber, pageSize, languageId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetFreeNewsToDisplayForUser/${pageNumber}/${pageSize}/${languageId}`, null, callback)
  },

  // CoffeeQuotesCurrency
  getCoffeeQuotesCurrency(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrlForCoffeeQuotes}/api/CoffeeQuotesCurrency/GetCoffeequotescurrency`, null, callback)
  },

  // Email
  sendSupportEmail(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Email/sendSupportEmail`, null, body, callback)
  },

  requestForSupport(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Email/RequestForSupport`, null, body, callback)
  },

  // GlobalDifferential
  getGlobalDifferentialByCountryId(countryId, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/GlobalDifferential/GetGlobalDifferentialByCurrencyForUsers/${countryId}`, null, callback)
  },

  getGlobalDifferentialHistoryData(countryId, qualityName, countryName, qualityCode, authToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/GlobalDifferential/GetGlobalDifferentialHistoryData/${countryId}/${qualityName}/${countryName}?QualityCode=${qualityCode}`, authToken, callback)
  },

  // IceMarketData
  getUsersCoffeeQuotes({ userId, countryId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/IceMarketData/GetUsersCoffeeQuotes/${userId}/${countryId}`, null, callback)
  },

  // GetArbitrageData
  getAllTerminalAndArbitrageData({ contractName, idMarket }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrlForCoffeeQuotesCoffeeWeb}/api/TincapheAuth/GetAllTerminalAndArbitrageData/${contractName}/${idMarket}`, null, callback)
  },

  // LanguageMaster
  languageForNews(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/LanguageMaster/LanguageForNews`, null, callback)
  },

  // MarketWorkbook
  addWorkbook(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/MarketWorkbook/AddWorkbook`, null, body, callback)
  },
  deleteMarketWorkbookInBulk(idWorkBook, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/MarketWorkbook/DeleteMarketWorkbookinbulk/${idWorkBook}`, null, callback)
  },

  // MiscMaster
  getAllCountries(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/miscmaster/getAllCountries`, null, callback)
  },

  getCountryDetailsByCountryId(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/miscmaster/getCountryDetailsByCountryId/${id}`, null, callback)
  },

  getStateList(countryid, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/miscmaster/GetStateList/${countryid}`, null, callback)
  },

  // Notifications
  getUsersNotificationForUser({ userId, pageNumber, pageSize }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/Notifications/GetUsersNotificationForUser/${userId}/${pageNumber}/${pageSize}`, null, callback)
  },

  // OccupationType
  occupationType(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/OccupationType`, null, callback)
  },

  // Order
  createOrder(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Order/CreateOrder`, null, body, callback)
  },

  UpdateOrder(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/Order/UpdateOrder`, null, body, callback)
  },

  // PasswordService
  updateUserPassword({ userId, password }, generatedToken, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/passwordservice/updateUserPassword/${userId}/${password}`, generatedToken, null, callback)
  },

  changePassword({ id, password, currentPassword, isGoogleSignIn }, generatedToken, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/passwordservice/ChangePassword/${id}/${password}/${currentPassword}/${isGoogleSignIn}`, generatedToken, null, callback)
  },

  // PayPalPayment
  captureOrder({ paymentId, uuid }, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/PayPalPayment/CaptureOrder?OrderId=${paymentId}&RequestId=${uuid}`, null, null, callback)
  },

  makePayPalPayment(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/PayPalPayment/MakePayPalPayment`, null, body, callback)
  },

  // ShareList
  getAllShareList(generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/ShareList/GetAllShareList`, generatedToken, callback)
  },

  // ShortenUrl
  shortenUrl(shareUrl, generatedToken, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/ShortenUrl?longUrl=${shareUrl}`, generatedToken, null, callback)
  },

  // SMSService
  sendOtpWithTemplate(number, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SMSService/sendOtpWithTemplate/${number}`, null, callback)
  },

  verifyOtp({ sessionId, otp }, body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/SMSService/verifyOtp/${sessionId}/${otp}`, null, body, callback)
  },

  // StripePayment
  createStripePayment(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/StripePayment/CreateStripePayment`, null, body, callback)
  },

  // SubscriptionMaster
  getSubscriptionFeatureWithCountry({ countryId, isTestPayment }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionMaster/GetSubscriptionFeatueWithCountry?countryId=${countryId}&IstestPayment=${isTestPayment}`, null, callback)
  },

  // SubscriptionPaymentMapping
  getAllSubscriptionPaymentMappingById({ id, userId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/GetAllSubscriptionPaymentMappingById/${id}/${userId}`, null, callback)
  },

  // TerminalDetails
  getTerminalDetails(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrlForCoffeeQuotesCoffeeWeb}/api/TerminalDetails/GetTerminalDetails`, null, callback)
  },

  // GetCoffeeQuotesDataFromHttpURL
  getICEMartketTerminalDataFromHttpURL(callback) {
    return coffeeWebApiService.get(`${configUrl.coffeeQuotesIceMarketHttpUrl}`, null, callback)
  },

  // UserGSTDetail
  postUserGSTDetail(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/UserGSTDetail/PostUserGSTDetail`, null, body, callback)
  },

  getUserGSTDetailByList(userId, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/UserGSTDetail/GetUserGSTDetailByList/${userId}`, null, callback)
  },

  // UserService
  authenticateWithFB(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/AuthenticatewithFB`, null, body, callback)
  },

  createUser(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/createUser`, null, body, callback)
  },

  updateOnlyUser(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/UpdateOnlyUser`, null, body, callback)
  },

  deactivateAccount({ id, updatedBy }, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/DeactivateAccount/${id}/${updatedBy}`, null, null, callback)
  },

  generateAuthToken(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/generateAuthToken`, null, callback)
  },

  getUserByUserId(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/getUserByUserId/${id}/${true}`, null, callback)
  },

  getUserByPhone({ phone, selectedCountryId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/getUserByPhone/${phone}/${selectedCountryId}`, null, callback)
  },

  isEmailExist({ email, id }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/isEmailExist/${email}?Userid=${id}`, null, callback)
  },

  sendForgotPasswordLink({ email }, generatedToken, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Email/ForgotPasswordLink/${email}`, generatedToken, callback)
  },

  getUserIdByEncryptedURL({ encryptedURL }, generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/GetTest/${encryptedURL}`, generatedToken, callback)
  },

  isPhoneExist({ phoneNo, countryPhoneCode }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/isPhoneExist?Phone=${phoneNo}&UserId=0&CountryPhoneCode=${countryPhoneCode}`, null, callback)
  },

  userLogout(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/UserLogout`, null, body, callback)
  },

  updateUserProfileSettings(id, body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/UpdateUserProfileSettings/${id}/${body.dateFormat}/${body.timeFormat}/${body.Languages}/${body.landingPage}/${body.timeZone}/Browser/false/${body.showWebNotification}`, null, body, callback)
  },

  getProfileSettingMasters(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/GetProfileSettingMasters`, null, callback)
  },

  deleteAccount(id, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/userservice/DeleteAccount/${id}`, null, callback)
  },

  updateIsLoggedIn({ userId, p2, p3, p4 }, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/updateIsLoggedIn/${userId}/${p2}/${p3}/${p4}`, null, null, callback)
  },
  updateTimeFormat({ userId, TimeFormat }, body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/UpdateUserTimeFormat/${userId}/${TimeFormat}`, null, body, callback)
  },
  updateNotificationReadAll(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/Notifications/UpdateNotificationReadAll`, null, body, callback)
  },
  getAllFreeMenusToDisplay(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/menumaster/GetAllFreeMenusToDisplay`, null, callback)
  },

  // Coffee Reports
  getDataByReportName({ reportName }, generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/S3Bucket/GetDataByReportName?ReportName=${reportName}`, generatedToken, callback)
  },

  //USDA Coffee Report
  getUSDACoffeeReport(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/USDACoffeeReport/getUSDACoffeeReport`, callback)
  }
}

export default apiAdapterCoffeeWeb
