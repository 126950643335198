import '../styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { getLocalLoggedInStatus } from 'Utils/LocalStorage_Handler'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { warningIcon } from 'Assets/Images'
import Button from 'Components/UIComponent/Button'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import { commonMethods } from 'Utils/commonMethods'

const LogoutModal = ({ showConfirmation, ...props }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { showLogoutPopup, isLogoutModalVisible, setIsLogoutModalVisible } = props
  const { setLoading } = useContext(CoffeeWebContext)
  const { handleUserLogout, refreshLocalStorage } = commonMethods

  const [countDownTimer, setCountDownTimer] = useState(5)

  const handleLogout = async () => {
    setLoading(true)
    sessionStorage.setItem('logoutFlag', 'true')

    try {
      await handleUserLogout()
      history.push({
        pathname: ROUTE_STRINGS.guestCoffeeQuotes
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleOnChangeStorageEvent = () => {
      const isLoggedIn = getLocalLoggedInStatus()

      if (!isLoggedIn) {
        autoLogout()
      }
    }

    window.addEventListener('storage', handleOnChangeStorageEvent)

    return () => {
      window.removeEventListener('storage', handleOnChangeStorageEvent)
    }
  }, [])

  const openModal = () => {
    showConfirmation({
      title: t('LOGOUT_ACCOUNT'),
      message: t('CONFIRM_LOGOUT'),
      buttonLabel: t('YES'),
      dismissButtonLabel: t('NO'),
      onConfirm: () => {
        handleLogout()
      },
      onCancel: () => {
        setIsLogoutModalVisible(false)
      }
    })
  }

  useEffect(() => {
    if (isLogoutModalVisible) {
      openModal()
    }
  }, [isLogoutModalVisible])

  useEffect(() => {
    let timer

    if (showLogoutPopup) {
      timer = setInterval(() => {
        setCountDownTimer((prev) => (prev > 0 ? prev - 1 : prev))
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [showLogoutPopup])

  const autoLogout = () => {
    refreshLocalStorage()
    sessionStorage.clear()
    history.push({
      pathname: ROUTE_STRINGS.guestCoffeeQuotes
    })
  }

  const automaticLogoutModalContent = () => (
    <div className="logoutPopupModalBodyWrapper" data-testid="logout-popup-modal-wrapper">
      <div className="logoutPopupModalBodyImageDiv">
        <img alt="warning Icon" src={warningIcon} className="logout-popup-warning-icon" title="warning-icon" />
      </div>
      <div className="countDownCircleContainer" data-testid="countdown-circle-container">
        <div className="circular-progress">
          <svg viewBox="0 0 50 50" width="50" height="50" xmlns="http://www.w3.org/2000/svg">
            <path className="background-circle" d="m 25,1.5 a 23.5,23.5 0 1,0 0,47 a 23.5,23.5 0 1,0 0,-47"></path>
            <path className="progress-circle" d="m 25,1.5 a 23.5,23.5 0 1,0 0,47 a 23.5,23.5 0 1,0 0,-47"></path>
          </svg>
          <div className="center-text" data-testid="countdown-timer">
            <h6 className="remaining-time-text">{countDownTimer}</h6>
          </div>
        </div>
      </div>
      <h5 className="logoutPopupText">{t('ALREADY_LOGGED_IN')}</h5>
    </div>
  )

  const automaticLogoutModalFooter = (
    <div className="automatic-logout-modal-footer-button-wrapper">
      <Button buttonType="CUSTOM" buttonLabel={t('OK')} onButtonClick={() => autoLogout()} />
    </div>
  )

  return (
    <div className="logoutPopup" data-testid="logout-popup-container">
      <DialogUIModal showModal={showLogoutPopup} showLogo={true} bodyContent={automaticLogoutModalContent} footerContent={automaticLogoutModalFooter} hideCloseIcon={true} />
    </div>
  )
}

export default ErrorMessageModal(LogoutModal)
