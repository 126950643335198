/* eslint-disable */
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { setLocalWithFirebaseFCMToken } from 'Utils/LocalStorage_Handler'

const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY
const firebaseConfig = {
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  apiKey: process.env.REACT_APP_API_KEY,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const storage = getStorage()
export const db = getFirestore()
export const messaging = getMessaging()

const initializeFirebaseMessaging = () => {
  getToken(messaging, { vapidKey })
    .then((currentToken) => {
      if (currentToken) {
        setLocalWithFirebaseFCMToken(currentToken)
      } else {
        console.log('can not get token')
      }
    })
    .catch((error) => {
      console.error('An error occurred while retrieving token. ', error)
    })

  onMessage(messaging, (payload) => {
    if (Notification.permission === 'granted') {
      createNotification(payload.notification)
    }
  })

  function createNotification(notification) {
    console.log(notification)
    try {
      if (Notification.permission === 'granted') {
        new Notification(notification.title, {
          body: notification.body,
          icon: '/firebase-logo.png'
        })
      } else {
        console.log('Notification permission not granted.')
      }
    } catch (error) {
      console.error('Error creating notification:', error)
    }
  }
}

export function requestPermission() {
  Notification.requestPermission()?.then((permission) => {
    if (permission === 'granted') {
      registerServiceWorker()
    } else {
      console.log('permission denied')
    }
  })
}

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    await navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope)
        initializeFirebaseMessaging()
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error)
      })
  }
}

export const detectOS = () => {
  const platform = navigator.platform.toLowerCase()

  if (platform.includes('win')) {
    return 'Windows'
  } else if (platform.includes('mac')) {
    return 'Mac'
  } else if (platform.includes('iphone') || platform.includes('ipad') || platform.includes('ipod')) return 'iOS'
}
