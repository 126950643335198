import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { useTranslation } from 'react-i18next'
import { EyeFilled, EyeFilledOff } from 'Assets/Icons'
import Validation from 'Utils/Validation'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import Button from 'Components/UIComponent/Button'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const ResetPasswordModal = ({ handleCloseModal, showSuccess, resetPasswordModal, mockResetPasswordModalApi, mockUserIdData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const authMode = urlParams.get('authMod')
  const encryptedURL = authMode?.split('/')[1]
  const { setLoading } = useContext(CoffeeWebContext)

  // testing purpose
  useEffect(() => {
    if (mockResetPasswordModalApi) {
      getUserIdByEncryptedURL()
      changePassword(mockUserIdData)
    }
  }, [])

  const [userInputs, setUserInputs] = useState({
    newPassword: '',
    confirmPassword: ''
  })

  const [errorState, setErrorState] = useState({
    newPassword: '',
    confirmPassword: ''
  })

  const [passwordMisMatch, setPasswordMisMatch] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleInputChange = ({ name, value }) => {
    setUserInputs((prev) => ({ ...prev, [name]: value }))
    setPasswordMisMatch('')

    if (name === 'newPassword') {
      setErrorState((prev) => ({ ...prev, [name]: Validation.isInputFieldEmpty(value) ? 'NEW_PASSWORD_IS_REQUIRED' : '' }))
    } else if (name === 'confirmPassword') {
      setErrorState((prev) => ({ ...prev, [name]: Validation.isInputFieldEmpty(value) ? 'CONFIRM_PASSWORD_IS_REQUIRED' : '' }))
    }
  }

  const handleInputBlur = ({ name, value }) => {
    if (name === 'newPassword' && value === '') {
      setErrorState((prev) => ({ ...prev, [name]: 'NEW_PASSWORD_IS_REQUIRED' }))
    } else if (name === 'confirmPassword' && value === '') {
      setErrorState((prev) => ({ ...prev, [name]: 'CONFIRM_PASSWORD_IS_REQUIRED' }))
    }
    checkforPasswordMismatch()
  }

  const checkforPasswordMismatch = () => {
    const { newPassword, confirmPassword } = userInputs

    if (newPassword !== confirmPassword) {
      setPasswordMisMatch('PASSWORD_MISMATCH')
    }
  }

  const handleClickResetPassword = () => {
    const { newPassword, confirmPassword } = userInputs

    if (newPassword === '') {
      setErrorState((prev) => ({ ...prev, newPassword: 'NEW_PASSWORD_IS_REQUIRED' }))
    } else if (confirmPassword === '') {
      setErrorState((prev) => ({ ...prev, confirmPassword: 'CONFIRM_PASSWORD_IS_REQUIRED' }))
    } else {
      checkforPasswordMismatch()

      if (!passwordMisMatch) {
        getUserIdByEncryptedURL()
      }
    }
  }

  const getUserIdByEncryptedURL = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getUserIdByEncryptedURL({ encryptedURL })

      if (response.data) {
        changePassword({ userId: response?.data })
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const changePassword = async ({ userId }) => {
    const payload = {
      id: userId,
      password: userInputs.newPassword,
      currentPassword: 'NA',
      isGoogleSignIn: true
    }

    try {
      const response = await apiAdapterCoffeeWeb.changePassword(payload)

      if (response?.data) {
        urlParams.set('authMod', `password-reset-successfully/${encryptedURL}`)
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`

        history.replace(newUrl)
        handleCloseModal()

        showSuccess({
          title: t('SUCCESS'),
          message: t('YOUR_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY'),
          buttonLabel: t('LOGIN_NOW')
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const resetPasswordModalBody = () => (
    <div className="reset-password-modal" data-testid="reset-password-modal">
      <div className="modal-body-style" data-testid="modal-body-style">
        <div className="inputWrapper" data-testid="password-input-wrapper">
          <input type={showNewPassword ? 'text' : 'password'} name="newPassword" onChange={(e) => handleInputChange(e.target)} onBlur={(e) => handleInputBlur(e.target)} className="reset-password-input pass_flow" placeholder={t('NEW_PASSWORD')} data-testid="new-password-input" />
          <span onClick={() => setShowNewPassword(!showNewPassword)} className="eye_icon">
            {showNewPassword ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" data-testid="password-eye-icon" />}
          </span>
          <div className="error-message" data-testid="password-error-message">
            {t(errorState.newPassword)}
          </div>
        </div>
        <div className="inputWrapper" data-testid="confirm-password-input-wrapper">
          <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" data-testid="confirm-password-input" onChange={(e) => handleInputChange(e.target)} onBlur={(e) => handleInputBlur(e.target)} className="reset-password-input pass_flow" placeholder={t('CONFIRM_PASSWORD')} />
          <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="eye_icon">
            {showConfirmPassword ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" data-testid="confirm-password-eye-icon" />}
          </span>
          <div className="error-message" data-testid="confirm-password-error-message">
            {t(errorState.confirmPassword)}
          </div>
          {userInputs.newPassword && userInputs.confirmPassword && <div className="error-message">{t(passwordMisMatch)}</div>}
        </div>
      </div>
    </div>
  )

  const resetPasswordModalFooterContent = (
    <>
      <Button buttonType={'CLOSE'} onButtonClick={handleCloseModal} />
      <Button buttonType={'CUSTOM'} buttonLabel={t('SAVE_PASSWORD')} onButtonClick={() => handleClickResetPassword()} />
    </>
  )

  return <DialogUIModal title={t('CHANGE_PASSWORD')} showModal={resetPasswordModal} handleClose={handleCloseModal} bodyContent={resetPasswordModalBody} footerContent={resetPasswordModalFooterContent} />
}

export default ErrorMessageModal(ResetPasswordModal)
