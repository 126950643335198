import './styles.scss'
import { PageNotFoundSvg } from 'Assets/Icons'

function PageNotFound() {
  return (
    <div className="pageNotFound" data-testid="page-not-found">
      <div className="mainDiv">
        <div>
          <img alt="page not found" src={PageNotFoundSvg} />
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
