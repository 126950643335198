import './style.scss'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { popupIcon } from 'Assets/Icons'
import { commonMethods } from 'Utils/commonMethods'

/* eslint-disable react/display-name */
const AccordianData = memo((props) => {
  const { item, keyID, handleSelectContract, testId } = props
  const { t } = useTranslation()

  const { getOptionExpiryColor, getFirstNoticeDayColor, getFontColorWithBold } = commonMethods

  const lastChng = item?.idMarket === 1 ? item?.lastChng : parseFloat(item?.lastChng || 0).toFixed(2)
  const chng = item?.idMarket === 1 ? item?.chng : parseFloat(item?.chng || 0).toFixed(2)
  const percentageVal = parseFloat(item?.percentageVal || 0).toFixed(2)
  const highRate = item?.idMarket === 1 ? item?.highRate : parseFloat(item?.highRate || 0).toFixed(2)
  const netHigh = item?.idMarket === 1 ? item?.netHigh : parseFloat(item?.netHigh || 0).toFixed(2)
  const netLow = item?.idMarket === 1 ? item?.netLow : parseFloat(item?.netLow || 0).toFixed(2)
  const lowRate = item?.idMarket === 1 ? item?.lowRate : parseFloat(item?.lowRate || 0).toFixed(2)
  const openRate = item?.idMarket === 1 ? item?.openRate : parseFloat(item?.openRate || 0).toFixed(2)
  const prevRate = item?.idMarket === 1 ? item?.prevRate : parseFloat(item?.prevRate || 0).toFixed(2)
  const bid = item?.idMarket === 1 ? item?.bid : parseFloat(item?.bid || 0).toFixed(2)
  const ask = item?.idMarket === 1 ? item?.ask : parseFloat(item?.ask || 0).toFixed(2)

  const selectContract = (item) => {
    handleSelectContract(item, true)
  }

  return (
    <React.Fragment key={keyID}>
      <tr
        onClick={() => {
          selectContract(item)
        }}
        data-testId={testId}
        key={keyID}
        id="coffeequotes_accordian_component"
        className={item?.isHighlightRow}
      >
        {/* Contract Name */}
        <td className="contract_names" data-title={`${item?.marketName ?? ''} Contracts`}>
          <div className="contract-arbitrage-wrapper" data-testid="contract-name">
            {item?.contractName}
            <img className="popup-icon" src={popupIcon} alt="popup-icon" data-testid="popup-icon" />
          </div>
        </td>

        {/* Last */}
        <td className={getFontColorWithBold(item?.chng)} data-title={t('LAST')} data-testid="last-change">
          {lastChng}
        </td>

        {/* Chg */}
        <td className={`${getFontColorWithBold(item?.chng)} `} data-title={t('CHG')} data-testid="change-table-row">
          <div className={`sign-value-wrapper-${item?.idMarket === 1 ? 'robusta' : 'arabica'}`}>
            <span>{item?.chng >= 0 ? '+' : '-'}</span>
            <span>{item?.idMarket === 1 ? Math.abs(item?.chng).toString().padStart(2, '0') : Math.abs(parseFloat(item?.chng || 0)).toFixed(2)}</span>
          </div>
        </td>

        {/* % */}
        <td className="percentage " data-title="%" data-testid="percentage-value">
          <div className="sign-value-wrapper-percent">
            <span>{item?.percentageVal >= 0 ? '+' : '-'}</span>
            <span> {Math.abs(parseFloat(item?.percentageVal || 0)).toFixed(2)}</span>
          </div>
        </td>

        {/* Volume */}
        <td className="volume" data-title={t('VOL')} data-testid="volume-value">
          {item?.volume}
        </td>

        {/* High */}
        <td data-title={t('HIGH')} data-testid="high-rate">
          {highRate}
        </td>

        {/* Net-High */}
        <td data-title="$-High" data-testid="net-high">
          <div data-testid="sign-value-wrapper" className={`${getFontColorWithBold(item?.netHigh)} sign-value-wrapper-${item?.idMarket === 1 ? 'robusta' : 'arabica'}`}>
            <span>{item?.netHigh >= 0 ? '+' : '-'}</span>
            <span>{item?.idMarket === 1 ? Math.abs(item?.netHigh).toString().padStart(2, '0') : Math.abs(parseFloat(item?.netHigh || 0)).toFixed(2)}</span>
          </div>
        </td>

        {/* Low */}
        <td data-title={t('LOW')} data-testid="low-rate">
          {lowRate}
        </td>

        {/* Net-Low */}
        <td data-title="$-Low" data-testid="net-low">
          <div className={`${getFontColorWithBold(item?.netLow)} sign-value-wrapper-${item?.idMarket === 1 ? 'robusta' : 'arabica'}`}>
            <span>{item?.netLow >= 0 ? '+' : '-'}</span>
            <span>{item?.idMarket === 1 ? Math.abs(item?.netLow).toString().padStart(2, '0') : Math.abs(parseFloat(item?.netLow || 0)).toFixed(2)}</span>
          </div>
        </td>

        {/* Open */}
        <td data-title={t('OPEN')} data-testid="open-rate">
          {openRate}
        </td>

        {/* Pre Close */}
        <td data-title={t('PRE_CLOSE')} data-testid="previous-close-rate">
          {prevRate}
        </td>

        {/* Open Int */}
        <td data-title={t('OPEN_INT')} data-testid="open-interest">
          {item?.openInterest}
        </td>

        {/* Bid */}
        <td data-title={t('BID')} data-testid="Bid">
          {bid}
        </td>

        {/* B-size */}
        <td data-title={t('BSIZE')} data-testid="bid-size">
          {item?.bsize}
        </td>

        {/* Ask */}
        <td data-title={t('ASK')} data-testid="ask-price">
          {ask}
        </td>

        {/* A-size */}
        <td data-title={t('LET_S_HELP')} data-testid="a-size">
          {item?.asize}
        </td>

        {/* Option Expiry */}
        <td data-title={t('OPTION_EXPIRY')} data-testid="option-expiry" className={getOptionExpiryColor(item?.optionExpiryStatus)}>
          <div>{item?.optionExpiryDateFormat?.toUpperCase()}</div>
        </td>

        {/* First Notice Date */}
        <td data-title={t('FIRST_NOTICE_DAY')} data-testid="first-notice-day" className={getFirstNoticeDayColor(item?.firstNoticeDateStatus)}>
          <div>{item?.firstNoticeDateFormat?.toUpperCase()}</div>
        </td>

        {/* Popup icon */}
        <td>
          <img className="popup-icon" src={popupIcon} alt="popup-icon" />
        </td>
      </tr>
    </React.Fragment>
  )
})

export default AccordianData
