import './styles.scss'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import CarouselMedia from 'Components/UIComponent/CarouselMedia'
import { FilledHeartIcon, UnFilledHeartIcon } from 'Assets/Icons'
import { getLocalShareNewsId, getLocalUserDetails, setLocalWithSubscriptionNewsId } from 'Utils/LocalStorage_Handler'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Button from 'Components/UIComponent/Button'
import ShareNewsfeed from 'Screens/CoffeeNewsFeeds/Components/ShareNewsfeed'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ImagePreview from 'Components/UIComponent/ImagePreview'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import { commonMethods } from 'Utils/commonMethods'

const OpenSharedNews = ({ mockOpenSharedNews, mockCoffeeNewsData, mockLike }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const scrollableDivRef = useRef(null)
  const { setLoading } = useContext(CoffeeWebContext)
  const userDetails = getLocalUserDetails()
  const sharedNewsId = history.location.state?.encryptedId || getLocalShareNewsId()
  const { dateFormatBasedOnUser } = commonMethods

  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [like, setLike] = useState(false)
  const [shareButton, setShareButton] = useState(false)
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const [newsId, setNewsId] = useState('')
  const [scrollDirection, setScrollDirection] = useState('up')
  const [previousScrollTop, setPreviousScrollTop] = useState(0)

  const [showImagePreview, setShowImagePreview] = useState(false)
  const [imagePreviewActiveIndex, setImagePreviewActiveIndex] = useState(0)

  useEffect(() => {
    getCoffeeNewsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockOpenSharedNews) {
      setCoffeeNewsData(mockCoffeeNewsData)
      setLike(mockLike)
    }
  }, [])

  const getCoffeeNewsData = async () => {
    if (sharedNewsId) {
      setLoading(true)
      try {
        const response = await apiAdapterCoffeeWeb.getNewsByEncryptedUserId({
          newsId: sharedNewsId,
          userid: userDetails.id,
          MarkAsNotificationRead: true
        })

        const newsData = response?.data?.returnLst[0]
        const { newsForLock, newsForSubscription, id: newsId, showFullfreeNews } = newsData || {}

        const isSubscriptionValid = newsForSubscription?.includes(userDetails?.sub?.subType) && !newsForLock?.includes(userDetails?.sub?.subType)

        if (isSubscriptionValid || showFullfreeNews) {
          const data = addBlankTargetToAnchors(newsData.newsHtml)
          const newData = [newsData]?.map((ele) => ({ ...ele, newsHtml: data }))

          if (newsData.newsFavourite) {
            setLike((prevLike) => !prevLike)
          }

          setCoffeeNewsData(newData)
        } else {
          history.replace(ROUTE_STRINGS.subscription)
          setLocalWithSubscriptionNewsId(newsId)
        }
      } catch (err) {
        console.log('err', err)
      } finally {
        setLoading(false)
      }
    } else {
      history.push(ROUTE_STRINGS.coffeeNewsFeeds)
    }
  }

  const addToFavorites = async (selectedNewsData) => {
    const postData = {
      id: 0,
      idUser: userDetails.id,
      idNews: selectedNewsData.id,
      isFavourite: true
    }

    try {
      const response = await apiAdapterCoffeeWeb.postNewsUsersFavorite(postData)

      if (response?.data?.statusCode === 201) {
        const previousNewsData = coffeeNewsData[0]
        const NewsData = [{ ...previousNewsData, newsFavourite: response?.data?.returnLst }]

        setCoffeeNewsData(NewsData)
      }
      setLike(true)
    } catch (error) {
      console.log('Error', error)
    }
  }

  const removeFromFavorites = async ({ newsFavourite }) => {
    try {
      const response = await apiAdapterCoffeeWeb.makeNewsUnfavorite(newsFavourite.id)

      if (response?.data?.statusCode === 201) {
        const previousNewsData = coffeeNewsData[0]
        const NewsData = [{ ...previousNewsData, newsFavourite: response?.data?.returnLst }]

        setCoffeeNewsData(NewsData)
      }
      setLike(false)
    } catch (error) {
      console.log('Error', error)
    }
  }

  const getNewsId = (newsid) => {
    setNewsId(newsid?.id)
    setShareButton(true)
    setOpenLogoutModal(true)

    if (shareButton === true) {
      setShareButton(false)
    }
  }

  const handleClickBackButton = () => {
    history.replace(ROUTE_STRINGS.coffeeNewsFeeds)
  }

  const handleViewDocument = (fileName) => {
    window.open(fileName.filePath, '_blank')
  }

  const handleScroll = () => {
    const scrollableDiv = document.getElementById('scrollable-div')
    const width = window.innerWidth

    if (width < 767) {
      const { scrollTop: currentScrollPos, scrollHeight: currentScrollPoss, clientHeight: currentScrollPosss } = scrollableDiv
      const minValue = currentScrollPoss - 7

      if (currentScrollPos > previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('down')
        if (currentScrollPos + currentScrollPosss > minValue) {
          setScrollDirection('up')
        }
      } else if (currentScrollPos < previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('up')
      }
      setPreviousScrollTop(currentScrollPos)
    }
  }

  const handleImagePreview = (index) => {
    setShowImagePreview(true)
    setImagePreviewActiveIndex(index)
  }

  return (
    <>
      {coffeeNewsData && (
        <div className="news_read_more_screen_main_div" data-testid="open-shared-news-wrapper">
          <div>
            <div className="news_read_more ">
              <div className="main-card">
                {coffeeNewsData.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="image_div">
                      <div className="sticky_button">
                        <div className="back_button">
                          <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} variant={'soft-action'} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} buttonWidth={'full'} onButtonClick={handleClickBackButton} />
                        </div>
                        <div className="share_news_button">
                          <Button buttonType={'CUSTOM'} buttonLabel={t('SHARE')} buttonIcon={<i className="pi pi-share-alt share-icon" />} buttonWidth={'full'} onButtonClick={() => getNewsId(item)} />
                        </div>
                      </div>

                      {item.nwsFeedMedia?.length > 0 ? (
                        <div className="img_container" data-testid="dynamic-media-container">
                          {item.nwsFeedMedia?.map((ele, index) => (
                            <div key={index}>{ele.typeMedia === 'image' ? <img title="shared-news-image" alt={ele?.nameOfMedia} src={ele.pathOfMedia} className="assets" onClick={() => handleImagePreview(index)} /> : <video src={ele.pathOfMedia} title="shared-news-video" className="assets" controls />}</div>
                          ))}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="content_div" id="scrollable-div" ref={scrollableDivRef} onScroll={handleScroll}>
                      <div className={`sticky_button scrollBackBtn${scrollDirection === 'down' ? ' slide-down' : ' down'}`}>
                        <div className="back_button">
                          <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} variant={'soft-action'} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} buttonWidth={'full'} onButtonClick={handleClickBackButton} />
                        </div>
                        <div className="share_news_button">
                          <Button buttonType={'CUSTOM'} buttonLabel={t('SHARE')} buttonIcon={<i className="pi pi-share-alt share-icon" />} buttonWidth={'full'} onButtonClick={() => getNewsId(item)} />
                        </div>
                      </div>
                      <div className="sticky_div" data-testid="open-shared-news-container">
                        {scrollDirection === 'up' && (
                          <div className="sub_sticky_div">
                            <div className="sticky_icons_container" data-testid="news-date">
                              <i className="pi pi-calendar social-feed-icons calendar-clock-icons" data-testid="calendar-icon" />
                              {dateFormatBasedOnUser(item.customdate, 'spaceFormat')}
                            </div>
                            <div className="sticky_icons_container" data-testid="news-time">
                              <i className="pi pi-clock social-feed-icons calendar-clock-icons" data-testid="clock-icon" />
                              {item.customtime}
                            </div>
                          </div>
                        )}

                        <div className="banner_image" data-testid="news-subject">
                          {ReactHtmlParser(item.subject)}
                        </div>
                        <div className="sticky_box_bottom_part"></div>
                        {scrollDirection === 'up' && (
                          <div className="sub_sticky_div">
                            <div className="account_icon_container" data-testid="news-author">
                              <i className="pi pi-user social-feed-icons account-icon user-account-icon" data-testid="user-icon" />
                              <span className="author-text">{item.author}</span>
                            </div>
                            <div className="right_part_icon_container">
                              <div className="view-icon-container" data-testid="news-views">
                                <i className="pi pi-eye social-feed-icons view-icon" data-testid="view-icon" />
                                {item.views}
                              </div>
                              {like === false ? (
                                <div onClick={() => addToFavorites(item)} className="heart_icon">
                                  <UnFilledHeartIcon fill={'red'} className="heartLogoBlob" data-testid="unfilled-heart-icon" />
                                </div>
                              ) : (
                                <div onClick={() => removeFromFavorites(item)} className="heart_icon">
                                  <FilledHeartIcon data-testid="filled-heart-icon" />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="carousel_for_news hide_carousel" data-testid="image-carousel-container">
                        <CarouselMedia mediaList={item?.nwsFeedMedia} handleImagePreview={handleImagePreview} />
                      </div>
                      {item.fileName && (
                        <div className="view_document_and_file_container">
                          <Button buttonType={'CUSTOM'} buttonLabel={t('VIEW_DOCUMENT')} onButtonClick={() => handleViewDocument(item)} />
                        </div>
                      )}

                      <div>
                        <div className="news_html" data-testid="news-element">
                          {ReactHtmlParser(item.newsHtml)}
                        </div>
                      </div>
                      <div className="news_html_fullstop">.</div>
                    </div>
                    {showImagePreview && <ImagePreview mediaList={item?.nwsFeedMedia} setShowImagePreview={setShowImagePreview} imagePreviewActiveIndex={imagePreviewActiveIndex} setImagePreviewActiveIndex={setImagePreviewActiveIndex} />}
                  </React.Fragment>
                ))}

                <div className="modal_body_share_news" data-testid="shared-news-modal">
                  {openLogoutModal && <ShareNewsfeed newsData={coffeeNewsData[0]} closeModal={() => setOpenLogoutModal(false)} newsidforselectred={newsId} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OpenSharedNews
