/* eslint-disable */
import './style.scss'
import React, { useEffect, useReducer, useState, useRef, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Marquee from 'react-fast-marquee'
import ReactHtmlParser from 'react-html-parser'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { useErrorBoundary } from 'react-error-boundary'
import { updateUserDetails, getLocalUserDetails, getLocalLoggedInStatus, setLocalWithLatestNewsReadNotification } from 'Utils/LocalStorage_Handler'
import TimerPicker from 'Components/TimerPicker'
import { RouteStrings } from 'Utils/Routes/RouteStrings'
import { onSnapshot, doc } from 'firebase/firestore'
import { db } from 'firebase.js'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS, COFFEEQUOTES_STATIC_VALUES } from 'Utils/Constants'
import AccordianData from './Components/AccordianData'
import AccordianForMobile from './Components/AccordianForMobile'
import AccordianPopUp from './Components/AccordianPopup'
import { useResizeHandler, commonMethods } from 'Utils/commonMethods'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import CreateAccountInfoModal from 'Components/UIComponent/CreateAccountInfoModal'
import { coffeeQuotesDefaultValue } from 'Utils/coffeeQuotesDummyData'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import WorkBook from './Components/WorkBook'

const CoffeeQuotes = ({ showError, showSuccess, showConfirmation, coffeeQuotesMockFlag, coffeeQuotesMockApiFlag }) => {
  const userDetails = getLocalUserDetails()
  const userLoginStatus = getLocalLoggedInStatus()

  const { id: userId, countryId, isCoffeeQuotesAvailable: isTincapheUser, isWorkBookAvailable } = userDetails || {}
  const isIndianUser = userDetails?.countryId === 102 || userDetails?.userCountryDetails?.nicename === 'India'
  const isPlatinumUser = userDetails?.sub?.subType === 3

  const { isMarketLive, getButtonAndInfo, dateFormatBasedOnUser } = commonMethods
  const { websocketReconnectionInterval, socketHealthCheckInterval, handleWorkbookDropDown } = COFFEEQUOTES_STATIC_VALUES

  const { isWebView, isMobileView } = useResizeHandler()
  const params = new URLSearchParams()
  const { setLoading } = useContext(CoffeeWebContext)
  const { showBoundary } = useErrorBoundary()
  const { t } = useTranslation()
  const history = useHistory()

  const initialState = {
    robustaData: coffeeQuotesDefaultValue,
    arabicaData: coffeeQuotesDefaultValue,
    workbookRobustaData: null,
    workbookArabicaData: null,
    selectedCurrencyRobusta: 0,
    selectedCurrencyArabica: 0,
    fxCurrencyList: null,
    accordianPopUp: false,
    lastUpdatedTimingForCoffeequotes: Date.now()
  }

  const reducer = (state, action) => {
    return { ...state, ...action.payload }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const workBookComponentRef = useRef(null)
  const robustaRef = useRef(state.robustaData)
  const arabicaRef = useRef(state.arabicaData)
  const workbookRobustaDataRef = useRef(state.workbookRobustaData)
  const workbookArabicaDataRef = useRef(state.workbookArabicaData)
  const selectedCurrencyRobustaRef = useRef(state.selectedCurrencyRobusta)
  const selectedCurrencyArabicaRef = useRef(state.selectedCurrencyArabica)
  const fxCurrencyListRef = useRef(state.fxCurrencyList)
  const accordianPopUpRef = useRef(state.accordianPopUp)
  const lastMessageReceivedAtRef = useRef(state.lastMessageReceivedAt)
  const coffeequoteDataErrorToast = useRef(null)

  const [marquee, setMarquee] = useState([])
  const [selectedContract, setSelectedContract] = useState({})
  const [searchContractList, setSearchContractList] = useState([])
  const [searchContractListForRobusta, setSearchContractListForRobusta] = useState([])
  const [searchContractListForArabica, setSearchContractListForArabica] = useState([])
  const [accordianPopupData, setAccordianPopupData] = useState(null)
  const [accordianPopupListData, setAccordianPopupListData] = useState([])
  const [handleSelectedTerminal, setHandleSelectedTerminal] = useState({})
  const [seletectedWorkBookType, setSelectedWorkBookType] = useState(handleWorkbookDropDown[0].value)

  useEffect(() => {
    const firebaseCleanUpMethod = firebaseSnapshotForTrendingNews()
    const websocketCleanUpMethod = handleWebSocket()

    getTrendingNews()
    if (userDetails) {
      updateUser()
    }

    return () => {
      firebaseCleanUpMethod()
      websocketCleanUpMethod()
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (accordianPopUpRef?.current) {
      calculateTerminalDifference()
    }
  }, [state])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (coffeeQuotesMockFlag) {
      setAccordianPopupData(coffeeQuotesMockFlag?.mockDataForAccordianPopUp)
      setMarquee(coffeeQuotesMockFlag.mockDataForTrendingNews)

      accordianPopUpRef.current = true
      robustaRef.current = coffeeQuotesMockFlag?.mockDataForRobusta
      arabicaRef.current = coffeeQuotesMockFlag?.mockDataForArabica
      lastMessageReceivedAtRef.current = Date.now()
      dispatch({ payload: { accordianPopUp: true, robustaData: coffeeQuotesMockFlag?.mockDataForRobusta, arabicaData: coffeeQuotesMockFlag?.mockDataForArabica, lastMessageReceivedAt: Date.now() } })
    } else if (coffeeQuotesMockApiFlag) {
      getICEDataCoffeeQuotesDataFromHttpURL()
      connectToWebSocket()
    }
  }, [])

  const firebaseSnapshotForTrendingNews = () => {
    let cleanUp = () => {}

    let mounted = false

    if (userDetails?.phone || userDetails?.email || userLoginStatus === false) {
      const unSub = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
        if (mounted) {
          const firebaseResponse = doc.data()
          const subscriptionList = firebaseResponse?.LatestNewsForSubscription?.split(',')?.map((ele) => parseInt(ele.trim(), 10))
          const countryList = firebaseResponse?.LatestNewsForCountries?.split(',')?.map((ele) => parseInt(ele.trim(), 10))
          if ((subscriptionList?.includes(userDetails?.sub?.subType) && countryList.includes(countryId) && !firebaseResponse?.IsDeleted && firebaseResponse?.IsApproved) || firebaseResponse.IsFreeNews) {
            getTrendingNews()
          }
        } else {
          mounted = true
        }
      })

      return () => {
        unSub()
      }
    }

    return cleanUp
  }

  const getTrendingNews = async () => {
    try {
      let response

      if (!userDetails) {
        response = await apiAdapterCoffeeWeb.getAllFreeTrendingNewsForGuest()
      } else {
        response = await apiAdapterCoffeeWeb.getAllShortNewsForSubForCountry(userId)
      }

      setMarquee(response.data?.returnLst || [])
    } catch (error) {
      console.error(error)
    }
  }

  const updateUser = async () => {
    const response = await updateUserDetails()

    if (response?.status === 401) {
      showBoundary('unAuthorized')
    }
  }

  const handleWebSocket = () => {
    const { isMarketActive } = isMarketLive()
    let cleanUpWebSocket

    if (isMarketActive || isTincapheUser) {
      cleanUpWebSocket = connectToWebSocket()
    } else {
      getICEDataCoffeeQuotesDataFromHttpURL()
    }

    const checkForSocketHealth = setInterval(() => {
      const elapsedTime = Date.now() - lastMessageReceivedAtRef?.current
      const { isMarketActive } = isMarketLive()
      if (elapsedTime > 15000 && isMarketActive) {
        if (cleanUpWebSocket) {
          cleanUpWebSocket()
          lastMessageReceivedAtRef.current = Date.now()
          dispatch({ payload: { lastMessageReceivedAt: Date.now() } })
        }
        cleanUpWebSocket = connectToWebSocket('connect it again')
      }
    }, socketHealthCheckInterval) // 2000ms

    const reconnectSockectEvery5min = setInterval(() => {
      const { isMarketActive } = isMarketLive()

      if (cleanUpWebSocket) {
        cleanUpWebSocket()
      }
      if (isMarketActive) {
        cleanUpWebSocket = connectToWebSocket('refresh')
      }
    }, websocketReconnectionInterval) // 5min

    return () => {
      clearInterval(reconnectSockectEvery5min)
      clearInterval(checkForSocketHealth)
      if (cleanUpWebSocket) {
        cleanUpWebSocket()
      }
    }
  }

  const connectToWebSocket = (status) => {
    console.log('this is test console for websocket reconnection: ', status)

    const { isMarketActive } = isMarketLive()
    const socketUrl = isTincapheUser ? `${process.env.REACT_APP_BASE_WEBSOCKET_FOR_COFFEE_QUOTES}/api/GetAllTincapcheDataDetail` : `${process.env.REACT_APP_BASE_PROD_WEBSOCKET_FOR_COFFEE_QUOTES}/api/GetICEMartketTerminalData`
    const socket = new WebSocket(socketUrl)

    let coffeeqotesData
    let resetFlickeringTimer

    if (robustaRef.current[0].optionExpiry === 'DD-MMM-YYYY') {
      setLoading(true)
    }

    if (!isMarketActive) {
      setTimeout(() => socket.close(), 1 * 60 * 1000)
    }

    socket.onmessage = (event) => {
      if (robustaRef.current[0].optionExpiry === 'DD-MMM-YYYY') {
        setLoading(false)
      }
      clearTimeout(resetFlickeringTimer)
      resetFlickeringTimer = setTimeout(() => {
        resetFlickeringEffect()
      }, 1500)

      if (isTincapheUser) {
        coffeeqotesData = JSON.parse(event.data)?.ReturnLst?.CoffeeQuotesTincapcheData
      } else {
        coffeeqotesData = JSON.parse(event.data)?.ReturnLst?.CoffeeQuotesICEData
      }

      const currencyList = JSON.parse(event.data)?.ReturnLst?.CoffeequotescurrencyData

      handleFilterCoffeeQuotesData(coffeeqotesData, currencyList)
    }

    socket.onclose = function () {
      console.log('WebSocket connection closed here')
    }

    function closeConnection() {
      socket.close()
    }

    return closeConnection
  }

  const getICEDataCoffeeQuotesDataFromHttpURL = async () => {
    setLoading(true)
    const response = await apiAdapterCoffeeWeb.getICEMartketTerminalDataFromHttpURL()

    try {
      const { statusCode, returnLst } = response.data

      if (statusCode === 200) {
        const coffeeqotesData = returnLst?.coffeeQuotesICEData
        const currencyList = returnLst?.coffeequotescurrencyData

        handleFilterCoffeeQuotesData(coffeeqotesData, currencyList)
      }
    } catch {
      coffeequoteDataErrorToast.current.show({ severity: 'error', summary: t('ERROR'), detail: t('FAILED_TO_GET_DATA') })
    } finally {
      setLoading(false)
    }
  }

  const handleFilterCoffeeQuotesData = (coffeeqotesData, currencyList) => {
    if (coffeeqotesData?.length > 0) {
      const robustaAndArabicaArray = coffeeqotesData
      const robustaFilteredData = robustaAndArabicaArray?.filter((item) => item.idMarket === 1)
      const arabicaFilteredData = robustaAndArabicaArray?.filter((item) => item.idMarket === 2)

      robustaAndArabicaArray.forEach((item) => {
        item.optionExpiryDateFormat = dateFormatBasedOnUser(item.optionExpiryDateFormat)
        item.firstNoticeDateFormat = dateFormatBasedOnUser(item.firstNoticeDateFormat)
      })

      const updateHighlightStatus = (currentPrices, previousDataSet) =>
        currentPrices.map((item, index) => {
          const hasPreviousData = previousDataSet?.[0]?.lastChng
          const isPriceChanged = item?.chng !== previousDataSet?.[index]?.chng
          const isBidChanged = item?.bid !== previousDataSet?.[index]?.bid

          const shouldHighlight = hasPreviousData && (isPriceChanged || (!isTincapheUser && isBidChanged))
          return {
            ...item,
            isHighlightRow: shouldHighlight ? 'highlight_color' : ''
          }
        })

      const robustaData = updateHighlightStatus(robustaFilteredData, robustaRef?.current)
      const arabicaData = updateHighlightStatus(arabicaFilteredData, arabicaRef?.current)

      if (!accordianPopUpRef.current && (isWorkBookAvailable || (isPlatinumUser && isIndianUser)) && workBookComponentRef?.current) {
        workBookComponentRef.current.filterWorkBookDataResponse(robustaData, arabicaData, currencyList)
      } else {
        robustaRef.current = robustaData
        arabicaRef.current = arabicaData
        lastMessageReceivedAtRef.current = Date.now()
        dispatch({ payload: { robustaData, arabicaData, lastMessageReceivedAt: Date.now() } })
      }
    }
  }

  const resetFlickeringEffect = () => {
    const updateHighlightStatus = (currentData) => currentData?.map((item) => ({ ...item, isHighlightRow: '' }))

    const robustaData = updateHighlightStatus(robustaRef?.current)
    const arabicaData = updateHighlightStatus(arabicaRef?.current)

    robustaRef.current = robustaData
    arabicaRef.current = arabicaData

    dispatch({ payload: { robustaData, arabicaData } })
  }

  const handleSelectContractDropdown = (e) => {
    const workBookType = parseInt(e.target.value, 10)
    setSelectedWorkBookType(workBookType)
    setSearchContractList(workBookType === 1 ? searchContractListForRobusta : searchContractListForArabica)
    setSelectedContract({})
  }

  const addWorkBookRowToRespectiveTable = async () => {
    if (seletectedWorkBookType === 3 || seletectedWorkBookType === 4 || selectedContract?.contractName) {
      workBookComponentRef.current.addWorkBookRowToRespectiveTable()
    }
  }

  const calculateTerminalDifference = (terminal) => {
    const item = terminal || handleSelectedTerminal
    const isRobusta = item?.idMarket == 1
    let targetedIndex = null
    let actualData = isRobusta ? robustaRef?.current : arabicaRef?.current
    let selectedTerminal = actualData?.find((ele, index) => {
      if (ele?.contractName == item?.contractName) {
        targetedIndex = index
        return ele
      }
    })
    if (item.optionExpiry !== 'DD-MMM-YYYY') {
      if (selectedTerminal) {
        let arrayList = []
        setAccordianPopupData(selectedTerminal)
        const { contractName, lastChng, chng } = selectedTerminal
        for (let i = 1; i < actualData.length - targetedIndex; i++) {
          let object = {
            parentTerminal: contractName,
            parentLastChng: lastChng,
            parentLastChngColor: chng >= 0 ? 'positive_green' : 'negative_red',
            childTerminal: actualData[targetedIndex + i]?.contractName,
            childTerminalChng: actualData[targetedIndex + i]?.lastChng,
            childLastChngColor: actualData[targetedIndex + i]?.chng >= 0 ? 'positive_green' : 'negative_red',
            difference: actualData[targetedIndex + i]?.lastChng - lastChng,
            differenceColor: actualData[targetedIndex + i]?.lastChng - lastChng >= 0 ? 'positive_green' : 'negative_red'
          }
          arrayList.push(object)
        }

        setAccordianPopupListData(arrayList)
      }
    }
  }

  const handleSelectContract = (terminal) => {
    if (terminal.optionExpiry !== 'DD-MMM-YYYY') {
      calculateTerminalDifference(terminal)
      setHandleSelectedTerminal(terminal)
      accordianPopUpRef.current = true
      dispatch({ payload: { accordianPopUp: true } })
    }
  }

  const handleCloseAccordianModal = () => {
    setHandleSelectedTerminal({})
    setAccordianPopupListData([])

    accordianPopUpRef.current = false
    dispatch({ payload: { accordianPopUp: false } })
  }

  const readMoreNews = (item) => {
    const { newsLock, id } = item
    if (!newsLock) {
      setLocalWithLatestNewsReadNotification(id)
      params.set('newsId', id)

      history.push({
        search: params.toString(),
        state: { pageRef: 'yes' },
        pathname: RouteStrings.opennewsnotification
      })
    } else history.push(RouteStrings.subscription)
  }

  const guestReadMoreNews = (item) => {
    const { showFullfreeNews, id } = item
    if (showFullfreeNews === true) {
      setLocalWithLatestNewsReadNotification(id)
      params.set('newsId', id)

      history.push({
        search: params.toString(),
        state: { pageRef: 'yes' },
        pathname: ROUTE_STRINGS.guestNewsFeed
      })
    } else {
      redirectForAuthentication(item)
    }
  }

  const redirectForAuthentication = (item) => {
    const path = userLoginStatus ? ROUTE_STRINGS.createAccount : ROUTE_STRINGS.login
    history.push({
      pathname: path,
      state: {
        encryptedId: item.encryptedId,
        newsForSubscription: item.newsForSubscription,
        newsId: item.id,
        forGuestUser: true
      }
    })
  }

  const robustaAndArabicaTerminalTableHeaderTemplate = (contractType) => (
    <tr className="table_head" data-testId="coffee-quotes-table-head">
      <td className="contract_name" data-testId="contract-name-container">
        <img className="country-flag-icon" src={`https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/${contractType === 'robusta' ? 'GBR' : 'USA'}.svg`} alt="Flag" title="contract-flag" />
        {contractType === 'robusta' ? <>{t('ROBUSTA_CONTRACTS')}</> : <>{t('ARABICA_CONTRACTS')}</>}
      </td>
      <td>{t('LAST')}</td>
      <td className="chng-cell">{t('CHG')}</td>
      <td>%</td>
      <td>{t('VOL')}</td>
      <td>{t('HIGH')}</td>
      <td>{t('NET_HIGH')}</td>
      <td>{t('LOW')}</td>
      <td>{t('NET_LOW')}</td>
      <td>{t('OPEN')}</td>
      <td>{t('PRE_CLOSE')}</td>
      <td>{t('OPEN_INT')}</td>
      <td>{t('BID')}</td>
      <td>{t('BSIZE')}</td>
      <td>{t('ASK')}</td>
      <td>{t('ASIZE')}</td>
      <td>{t('OPTION_EXPIRY')}</td>
      <td>{t('FIRST_NOTICE_DAY')}</td>
      <td></td>
    </tr>
  )

  const { buttonText, infoMessage, className, navigationPath } = getButtonAndInfo()

  return (
    <>
      <div className="coffee_quotes_screen" data-testId="coffee-quotes-container">
        {accordianPopUpRef?.current && <AccordianPopUp item={accordianPopupData} accordianPopupListData={accordianPopupListData} robustaData={robustaRef?.current} arabicaData={arabicaRef?.current} handleCloseAccordianModal={handleCloseAccordianModal} setLoading={setLoading} testId={'coffee-quotes-accordian-popup'} />}
        <div className="coffee_quotes_screen_header" data-testId="coffee-quotes-header">
          <div className="time_main_div_for_large_screen" data-testId="coffee-quotes-data-and-time-container">
            <div className="time_sub_div time" data-testId="coffee-quotes-data-container">
              {dateFormatBasedOnUser()}
            </div>
            <div className="time_sub_div" data-testId="coffee-quotes-time-container">
              <TimerPicker testId="time-picker-web-view" />
            </div>
          </div>
          <div className="time_main_div_for_mobile_screen" data-testId="coffee-quotes-date-and-time-container-mobile-view">
            <div className="time_sub_div" data-testId="date-container-mobile-view">
              <div>{t('DATE')}</div>
              <div className="time_colon">:</div>
              <div data-testId="date-container">{dateFormatBasedOnUser()}</div>
            </div>
            <div className="time_sub_div" data-testId="time-container-mobile-view">
              <div>{t('TIME')}</div>
              <div className="time_colon">:</div>
              <TimerPicker testId="time-picker-mobile-view" />
            </div>
          </div>

          {marquee.length > 0 && (
            <div className="for_trending_news" data-testId="coffee-quotes-marquee-main-container">
              <div className="labaling">{marquee.length ? `${t('TRENDING_NEWS')} :` : ''}</div>{' '}
              <Marquee pauseOnHover="true" speed="27" className="marquee_main_div">
                <>
                  {marquee?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="marquee_tag" onClick={() => (userDetails ? readMoreNews(item) : guestReadMoreNews(item))} data-testId="marquee-tag-container">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="bullet_dot" data-testId="bullet-dot-container"></div>
                          <div style={{ color: item?.trendingNewsColour == '#000000' ? '#000000' : item?.trendingNewsColour }} data-testId="marquee-tag-subject-container">
                            {ReactHtmlParser(item?.newsSubject)}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </>
              </Marquee>
            </div>
          )}
        </div>
        <div className="coffee_quotes_screen_body" data-testId="coffee-quotes-table-container">
          {/* Robusta and arabica table */}
          {isWebView && (
            <table className="robusta_and_arabica_table" data-testId="coffee-quotes-table-for-robusta-and-arabica">
              <tbody className="robusta_table" data-testId="table-body-for-robusta">
                {robustaAndArabicaTerminalTableHeaderTemplate('robusta')}
                {robustaRef?.current?.map((item, index) => (
                  <AccordianData handleSelectContract={handleSelectContract} item={item} keyID={index} key={index} testId={'coffee-quotes-robusta-accordian-data'} />
                ))}
              </tbody>
              <tbody className="arabica_table" data-testId="table-body-for-arabica">
                {robustaAndArabicaTerminalTableHeaderTemplate('arabica')}
                {arabicaRef?.current?.map((item, index) => (
                  <AccordianData handleSelectContract={handleSelectContract} item={item} keyID={index} key={index} testId={'coffee-quotes-arabica-accordian-data'} />
                ))}
              </tbody>
            </table>
          )}
          {isMobileView && (
            <>
              <AccordianForMobile data={robustaRef?.current} labeling={t('ROBUSTA')} idMarket={1} data-testId="accordion-for-mobile" />
              <AccordianForMobile data={arabicaRef?.current} labeling={t('ARABICA')} idMarket={2} data-testId="accordion-for-mobile" />
            </>
          )}
          <div className="coffeequotes-table-divider">{/* this div should be empty for divider */}</div>
          {isPlatinumUser || isWorkBookAvailable || isIndianUser ? (
            <>
              {isWebView && (
                <WorkBook
                  ref={workBookComponentRef}
                  dispatch={dispatch}
                  robustaRef={robustaRef}
                  arabicaRef={arabicaRef}
                  workbookRobustaDataRef={workbookRobustaDataRef}
                  workbookArabicaDataRef={workbookArabicaDataRef}
                  fxCurrencyListRef={fxCurrencyListRef}
                  setSearchContractList={setSearchContractList}
                  setSearchContractListForRobusta={setSearchContractListForRobusta}
                  setSearchContractListForArabica={setSearchContractListForArabica}
                  selectedContract={selectedContract}
                  seletectedWorkBookType={seletectedWorkBookType}
                  showError={showError}
                  showSuccess={showSuccess}
                  showConfirmation={showConfirmation}
                  lastMessageReceivedAtRef={lastMessageReceivedAtRef}
                  selectedCurrencyRobustaRef={selectedCurrencyRobustaRef}
                  selectedCurrencyArabicaRef={selectedCurrencyArabicaRef}
                  handleWorkbookDropDown={handleWorkbookDropDown}
                  handleSelectContractDropdown={handleSelectContractDropdown}
                  marquee={marquee}
                  searchContractList={searchContractList}
                  setSelectedContract={setSelectedContract}
                  testId={'coffee-quotes-work-book'}
                />
              )}
            </>
          ) : (
            <div className="creat_account_guest_info_modal">
              <CreateAccountInfoModal buttonText={t(`${buttonText}`)} infoMessage={t(`${infoMessage}`)} className={className} navigationPath={navigationPath} testId="coffee-quotes-create-account" state={{ subscriptionId: 3 }} />
            </div>
          )}
        </div>
      </div>
      <Toast ref={coffeequoteDataErrorToast} position="bottom-right" />
    </>
  )
}

export default ErrorMessageModal(CoffeeQuotes)
