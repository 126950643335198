import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import AppLinks from 'Components/LayoutComponents/AppLinks'
import { coffeewebLogo } from 'Utils/Constants'

const CreateAccountInfoModal = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { title, buttonText, infoMessage, className, navigationPath, state, testId } = props

  const navigateToLoginScreen = () => {
    history.push({
      pathname: navigationPath,
      state
    })
  }

  return (
    <div className="create-account-info-modal" data-testid={testId}>
      <div className={`content-wrapper ${className}`} data-testid="content-wrapper">
        <img className="coffeeweb-logo" src={coffeewebLogo.coloredLogo} alt="colored-logo" data-testid="coffeeweb-colored-logo" />
        <div className={`auth-screen-button ${className}`} onClick={navigateToLoginScreen} data-testid="auth-screen-button">
          {buttonText}
        </div>
        {title ? (
          <div className="title" data-testid="title">
            {title}{' '}
          </div>
        ) : (
          <div className="info">{infoMessage && <div>{infoMessage}</div>}</div>
        )}
        <div className="create-account-appLinks-wrapper" data-testid="app-links">
          <AppLinks />
        </div>
      </div>
    </div>
  )
}

export default CreateAccountInfoModal
