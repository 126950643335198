import './style.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TimeChevronUp } from 'Assets/Icons/Time_chevron_up.svg'
import { ReactComponent as TimeChevronDown } from 'Assets/Icons/Time_chevron_down.svg'
import { updateUserDetails, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'

const TimerPicker = ({ showConfirmation, testId }) => {
  const { t } = useTranslation()
  const userDetails = getLocalUserDetails()

  const [time, setTime] = useState('')
  const [formattedTime, setFormattedTime] = useState('')
  const [isTwelveHourFormat, setTwelveHourFormat] = useState(true)

  useEffect(() => {
    getTime()
  }, [userDetails?.userTimeFormat])

  const getTime = () => {
    setInterval(() => {
      const date = new Date()

      let x = 'AM'

      if (date.getHours() >= 12) {
        x = 'PM'
      }
      setTime(`${String(date.getHours() % 12 === 0 ? 12 : date.getHours() % 12).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')} ${x}`)
      setFormattedTime(`${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`)
    }, 1000)
    if (userDetails?.userTimeFormat === '12 Hr') {
      setTwelveHourFormat(true)
    } else if (userDetails?.userTimeFormat === '24 Hr') {
      setTwelveHourFormat(false)
    }
  }

  const updateTimeFormat = async () => {
    const userId = userDetails?.id
    const newTimeFormat = isTwelveHourFormat ? '24 Hr' : '12 Hr'

    try {
      const response = await apiAdapterCoffeeWeb.updateTimeFormat({ userId, TimeFormat: newTimeFormat })

      const { data } = await updateUserDetails()

      setTwelveHourFormat(!isTwelveHourFormat)

      setFormattedTime(response.data)
    } catch (error) {
      console.error('API error:', error)
    }
  }

  const handleTimeFormatClick = () => {
    if (userDetails) {
      showConfirmation({
        title: t('ARE_YOU_SURE'),
        message: t('DO_YOU_WANT_TO_CHANGE_THE_TIME_FORMAT_PERMANENTLY'),
        buttonLabel: t('YES'),
        onConfirm: () => {
          updateTimeFormat()
        },
        dismissButtonLabel: t('NO')
      })
    }
  }

  return (
    <>
      <div className="updated-time-pick" onClick={handleTimeFormatClick} data-testId={testId}>
        <div className="updated-time-pick-div" data-testId="updated-time-pick-container">
          <div data-testId="displayed-time"> {isTwelveHourFormat ? time : formattedTime}</div>
          <div className="updated-time-pick-arrows" data-testId="updated-time-pick-arrows">
            <TimeChevronUp />
            <TimeChevronDown />
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorMessageModal(TimerPicker)
