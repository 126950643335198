import './styles.scss'
import React, { useEffect, useState, useRef, useContext } from 'react'
import ReactHtmlParser from 'react-html-parser'
import CarouselMedia from 'Components/UIComponent/CarouselMedia'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Element } from 'react-scroll'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { FilledHeartIcon, UnFilledHeartIcon, Closebtn } from 'Assets/Icons'
import { getLocalAppSettings, getLocalUserDetails, setLocalWithIsNewsScreen, setLocalWithSubscriptionNewsId } from 'Utils/LocalStorage_Handler'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import Button from 'Components/UIComponent/Button'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'
import ImagePreview from 'Components/UIComponent/ImagePreview'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import { commonMethods } from 'Utils/commonMethods'
import ShareNewsfeed from '../ShareNewsfeed'

const NewsReadMore = ({ handleFavoriteAction, setOnRemoval, setNewsFeedStatus, redirectForAuthentication, handleNewsFalseModal, generatedToken, isPage, mockDataForTesting, favoriteIcon }) => {
  const appSettings = getLocalAppSettings()
  const userDetails = getLocalUserDetails()
  const { setLoading } = useContext(CoffeeWebContext)
  const { dateFormatBasedOnUser } = commonMethods

  const { t } = useTranslation()
  const location = useLocation()
  const scrollableDivRef = useRef(null)
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)

  const newsIdUpdated = searchParams.get('newsId')
  const isFullFreeNews = searchParams.get('showFullNews')
  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [shareNewsButton, setShareNewsButton] = useState(false)
  const [showShareNewsModal, setShowShareNewsModal] = useState(false)
  const [previousScrollTop, setPreviousScrollTop] = useState(0)
  const [scrollDirection, setScrollDirection] = useState('up')
  const [like, setLike] = useState(false)
  const [downloadModal, setDownloadModal] = useState(false)
  const [newsFavoriteId, setNewsFavoriteId] = useState(null)

  const [showImagePreview, setShowImagePreview] = useState(false)
  const [imagePreviewActiveIndex, setImagePreviewActiveIndex] = useState(0)

  const [newsItem] = useState(location?.state?.newItems)
  const [newsIndex] = useState(location?.state?.newIndex)

  const [newsDetails, setNewsDetails] = useState({
    newsId: null,
    author: null,
    customdate: null,
    customtime: null,
    newsHtml: null,
    filePath: null,
    fileType: null,
    nwsFeedMedia: null,
    encryptedId: null,
    newsForSubscription: null,
    subject: null,
    newsFavourite: null,
    views: 0
  })

  const { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } = newsDetails

  useEffect(() => {
    if (location.state?.newsId) {
      getNewsWithViewCount()
    } else {
      getCoffeeNewsData(generatedToken)
    }
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockDataForTesting) {
      setNewsDetails({ ...newsDetails, newsHtml: mockDataForTesting?.newsHtml, nwsFeedMedia: mockDataForTesting?.nwsFeedMedia, fileType: mockDataForTesting?.fileType })
      setDownloadModal(true)
      if (favoriteIcon) {
        setLike(favoriteIcon)
      }
    }
  }, [])

  const paramsData = (newsId) => {
    const params = new URLSearchParams(location.search)

    params.set('newsId', newsId)

    return params
  }

  const getNewsWithViewCount = async () => {
    setLoading(true)

    const { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } = location.state
    let updatedViews = views
    let updatedNewsFavourite = newsFavourite

    setLike(updatedNewsFavourite?.isFavourite)

    setNewsDetails((previousData) => ({ ...previousData, newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite: updatedNewsFavourite, views: updatedViews }))
    addBlankTargetToAnchors(newsHtml)
    const imageMatches = nwsFeedMedia?.filter((item) => item.typeMedia === 'image')

    try {
      if (!isFullFreeNews) {
        const response = await apiAdapterCoffeeWeb.updateNewsViewCountOnUser({ NewsId: newsIdUpdated, userid: userDetails?.id })
        const {
          data,
          data: { views, idNewsFavourite, newsFavourite }
        } = response

        updatedViews = views || updatedViews
        updatedNewsFavourite = newsFavourite || updatedNewsFavourite

        const newData = [data].map((ele) => ({ ...ele, encryptedId }))

        setCoffeeNewsData(newData)

        setNewsDetails((previousData) => ({ ...previousData, newsFavourite: updatedNewsFavourite, views: updatedViews }))
        setNewsFavoriteId(idNewsFavourite)
        setLike(newsFavourite?.isFavourite)

        if (location.state.newsFavourite?.isFavourite !== newsFavourite?.isFavourite) {
          handleFavoriteChange(idNewsFavourite, newsFavourite?.isFavourite)
        }

        const newsIdFromLocation = new URLSearchParams(window.location.search).get('newsId')

        if (Number(newsIdFromLocation) === Number(newsId)) {
          const params = paramsData(newsId)

          history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, idNewsFavourite, views } })
        }
      } else {
        setNewsDetails((previousData) => ({ ...previousData, newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite: updatedNewsFavourite, views: updatedViews }))
      }
      addBlankTargetToAnchors(newsHtml)
    } finally {
      setLoading(false)
    }
  }

  const getParameterValue = (parameter) => new URLSearchParams(window.location.search).get(parameter)
  const shareValue = getParameterValue('share')

  const getCoffeeNewsData = async () => {
    setLoading(true)
    setLike(newsFavourite?.isFavourite)
    try {
      let isRead = false

      if (userDetails?.id) {
        isRead = true
      }

      const payload = {
        userId: userDetails?.id || 0,
        newsId: newsIdUpdated,
        isRead
      }

      const {
        data: { returnLst: newsData }
      } = await apiAdapterCoffeeWeb.getNewsById(payload)

      const { newsForLock, newsForSubscription, id: newsId, showFullfreeNews } = newsData

      const subscribedNews = newsForSubscription.split(',')
      const lockedNews = newsForLock.split(',')

      const isSubscriptionValid = subscribedNews.includes(`${userDetails?.sub?.subType}`) && !lockedNews.includes(`${userDetails?.sub?.subType}`)

      if (isSubscriptionValid || showFullfreeNews) {
        const data = addBlankTargetToAnchors(newsData.newsHtml)
        const newData = [newsData].map((ele) => ({ ...ele, newsHtml: data }))

        const { id: newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } = newsData

        setCoffeeNewsData(newData)

        setNewsDetails((previousData) => ({ ...previousData, newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views }))
        if (newsData?.newsFavourite) {
          setLike((previousLike) => !previousLike)
        }

        document.querySelector('body').style.overflow = 'hidden'

        setLocalWithIsNewsScreen(true)
        const newsIdFromLocation = new URLSearchParams(window.location.search).get('newsId')

        if (Number(newsIdFromLocation) === Number(newsId)) {
          const params = paramsData(newsId)

          history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } })
        }
      } else {
        history.replace(ROUTE_STRINGS.subscription)
        setLocalWithSubscriptionNewsId(newsId)
      }
    } catch {
      setLocalWithIsNewsScreen(false)
      handleNewsFalseModal()
    } finally {
      setLoading(false)
    }
  }

  const addToFavorites = async () => {
    const postData = {
      id: 0,
      idUser: userDetails.id,
      idNews: newsId,
      isFavourite: true
    }

    try {
      await apiAdapterCoffeeWeb.postNewsUsersFavorite(postData).then((response) => {
        const { id, isFavourite } = response.data.returnLst

        const newsFavourite = response?.data?.returnLst

        setCoffeeNewsData({ ...coffeeNewsData, newsFavourite })
        setNewsFavoriteId(id)
        handleFavoriteChange(id, isFavourite, newsIndex, newsItem)
        setLike(true)

        const params = paramsData(newsId)

        history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } })
      })
    } catch (error) {
      console.log('err', error)
    } finally {
      setLoading(false)
    }
  }

  const removeFromFavorites = async (favouriteNewsId) => {
    try {
      await apiAdapterCoffeeWeb.makeNewsUnfavorite(favouriteNewsId).then((response) => {
        const newsFavourite = response?.data?.returnLst
        const previousNewsData = coffeeNewsData[0]

        setCoffeeNewsData({ ...previousNewsData, newsFavourite })
        setLike(false)
        setOnRemoval(true)
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NEWS_FEED_NO_DATA') }))

        const newsIdFromLocation = new URLSearchParams(window.location.search).get('newsId')

        if (Number(newsIdFromLocation) === Number(newsId)) {
          const params = paramsData(newsId)

          history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, newsFavourite, subject, views } })
        }

        handleFavoriteChange(favouriteNewsId, newsFavourite, newsIndex, newsItem)
      })
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleShowShareButton = () => {
    setShowShareNewsModal(true)
    setShareNewsButton(true)
    if (shareNewsButton) setShareNewsButton(false)
  }

  const handleScroll = () => {
    const scrollableDiv = document.getElementById('scrollable-div')
    const width = window.innerWidth

    if (width < 767) {
      const { scrollTop: currentScrollPos, scrollHeight: currentScrollPoss, clientHeight: currentScrollPosss } = scrollableDiv
      const minValue = currentScrollPoss - 7

      if (currentScrollPos > previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('down')
        if (currentScrollPos + currentScrollPosss > minValue) {
          setScrollDirection('up')
        }
      } else if (currentScrollPos < previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('up')
      }
      setPreviousScrollTop(currentScrollPos)
    }
  }

  const handleViewDocument = () => {
    setDownloadModal(true)
  }

  const handleBackButton = () => {
    if ((shareValue || isPage) === 'yes') {
      history.goBack()
    } else {
      setLocalWithIsNewsScreen(false)
      handleNewsFalseModal(coffeeNewsData[0])
    }
  }

  const handleFavoriteChange = (favouriteNewsId, isFavourite, newsIndex, newsItem) => {
    handleFavoriteAction(newsIdUpdated, favouriteNewsId, isFavourite, newsIndex, newsItem)
  }

  const handleFavoriteFreeNews = (encryptedId, newsForSubscription, newsId) => {
    redirectForAuthentication(encryptedId, newsForSubscription, newsId)
  }

  const ShareButtonComponent = () => (
    <div className="share-news-button">
      <Button buttonType={'CUSTOM'} buttonLabel={t('SHARE')} buttonIcon={<i className="pi pi-share-alt share-icon" />} buttonWidth={'full'} onButtonClick={() => handleShowShareButton()} />
    </div>
  )

  const containsKannada = /[ಅ-ಹ]/.test(subject)

  const handleImagePreview = (index) => {
    setShowImagePreview(true)
    setImagePreviewActiveIndex(index)
  }

  const previewPDFModalContent = (filePath) => (
    <div className="modal-read-more-download-pdf">
      <div className="modal-body-style">
        <iframe title="View Document" src={filePath} />
      </div>
    </div>
  )

  return (
    <>
      {newsHtml ? (
        <div className="news-read-more-screen-main-div" data-testid="news-read-more-wrapper">
          <div>
            <div className="news-read-more">
              <div className="main-card" data-testid="news-read-more-card">
                <React.Fragment>
                  <div className="image-div" data-testid="news-read-more-image-container">
                    <div className="sticky-buttons" data-testid="news-read-more-button-container">
                      <div className="back-button">
                        <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} variant={'soft-action'} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} buttonWidth={'full'} onButtonClick={handleBackButton} />
                      </div>
                      {((userDetails && appSettings?.showShareButton) || !userDetails) && <ShareButtonComponent />}
                    </div>

                    {nwsFeedMedia?.length > 0 ? (
                      <div className="img-container" data-testid="dynamic-media-container">
                        {nwsFeedMedia?.map((ele, index) => (
                          <div key={index}>{ele?.typeMedia === 'image' ? <img alt="" src={ele?.pathOfMedia} className="assets" onClick={() => handleImagePreview(index)} title="news-image" /> : <video src={ele?.pathOfMedia} className="assets" controls title="news-video" />}</div>
                        ))}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <Element className="content-div scrollable-div" id="scrollable-div" ref={scrollableDivRef} onScroll={handleScroll} data-testid="read-more-content-container">
                    <div className={`sticky-buttons scroll-back-button${scrollDirection === 'down' ? ' slide-down' : ' down'}`} data-testid="read-more-button-container-in-mobile-view">
                      <div className="back-button">
                        <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} variant={'soft-action'} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} buttonWidth={'full'} onButtonClick={handleBackButton} />
                      </div>
                      {((userDetails && appSettings?.showShareButton) || !userDetails) && <ShareButtonComponent />}
                    </div>
                    <div className="sticky-container-div" data-testid="read-more-header-container">
                      <div className="sticky-div">
                        {scrollDirection === 'up' && (
                          <div className="sub-sticky-div" data-testid="sticky-header-dynamic-data-and-time">
                            <div className="sticky-icons-container" data-testid="sticky-date-container">
                              <i className="pi pi-calendar social-feed-icons calendar-clock-icons" />
                              {dateFormatBasedOnUser(customdate, 'spaceFormat')}
                            </div>
                            <div className="sticky-icons-container" data-testid="sticky-time-container">
                              <i className="pi pi-clock social-feed-icons calendar-clock-icons" />
                              {customtime}
                            </div>
                          </div>
                        )}

                        <div className={`news-heading-subject ${containsKannada ? 'kannada-text' : 'banner-image'}`} data-testid="news-header-subject-container">
                          {ReactHtmlParser(subject)}
                        </div>

                        <div className="sticky-box-bottom-part"></div>
                        {scrollDirection === 'up' && (
                          <div className="sub-sticky-div" data-testid="sticky-header-dynamic-author-heart-icon-and-view">
                            <div className="account-icon-container" data-testid="sticky-author-container">
                              <i className="pi pi-user social-feed-icons account-icon user-account-icon" />
                              <span className="author-text">{author}</span>
                            </div>
                            <div className="right-part-icon-container">
                              <div className="view-icon-container" data-testid="sticky-view-count-container">
                                <i className="pi pi-eye social-feed-icons view-icon" /> {views}
                              </div>

                              {!like ? (
                                <div onClick={userDetails ? () => addToFavorites() : () => handleFavoriteFreeNews(encryptedId, newsForSubscription, newsId)} className="heart-icon" data-testid="red-heart-icon">
                                  <UnFilledHeartIcon className="heart-logo-blob" fill={'red'} />
                                </div>
                              ) : (
                                <div onClick={() => removeFromFavorites(coffeeNewsData[0]?.idNewsFavourite || newsFavoriteId)} className="heart-icon" data-testid="filled-heart-icon">
                                  <FilledHeartIcon />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="carousel-for-news hideCarosel" data-testid="image-carousel-container">
                      <CarouselMedia mediaList={nwsFeedMedia} handleImagePreview={handleImagePreview} />
                    </div>
                    {showImagePreview && <ImagePreview mediaList={nwsFeedMedia} setShowImagePreview={setShowImagePreview} imagePreviewActiveIndex={imagePreviewActiveIndex} setImagePreviewActiveIndex={setImagePreviewActiveIndex} />}

                    {fileType === 'pdf' && (
                      <div className="view-document-and-file-container">
                        <Button buttonType={'CUSTOM'} buttonLabel={t('VIEW_DOCUMENT')} onButtonClick={() => handleViewDocument()} />
                      </div>
                    )}
                    {fileType === 'xlsx' && (
                      <div className="view-document-and-file-container">
                        <a className="download-doc-link" href={filePath}>
                          {t('DOWNLOAD_DOCUMENT')}
                        </a>
                      </div>
                    )}

                    <div>
                      <div className="news-html" data-testid="news-html-content-container">
                        {ReactHtmlParser(newsHtml)}
                      </div>
                    </div>
                    <div className="news-html-full-stop">.</div>
                  </Element>
                </React.Fragment>
                <DialogUIModal title={t('COFFEE_NEWS_DATA_DOWNLOAD')} size={'lg'} showModal={downloadModal} handleClose={() => setDownloadModal(false)} bodyContent={() => previewPDFModalContent(filePath)} testId="pdf-view-container" />
                <div className="modal-body-share-news-read-more" data-testid="news-share-component">
                  {showShareNewsModal && <ShareNewsfeed closeModal={() => setShowShareNewsModal(false)} newsData={newsDetails || coffeeNewsData[0]} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="news-read-more-screen-main-div" data-testid={'news-read-more-container-while-api-call'}>
          <div className="news-read-more">
            <div className="main-card">
              <React.Fragment>
                <div className="image-div" data-testid="news-read-more-image-container-while-api-call">
                  <div className="sticky-buttons" data-testid="news-read-more-buttons">
                    <div className="back-button">
                      <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} variant={'soft-action'} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} buttonWidth={'full'} onButtonClick={handleBackButton} />
                    </div>
                    {((userDetails && appSettings?.showShareButton) || !userDetails) && <ShareButtonComponent />}
                  </div>

                  <div className="img-container">
                    <img alt="" src="https://www.coffeeweb.com/CoffeeWeb_Logo_White_Background_Blue_Text.png" title="default-image-while-api-call" className="assets" />
                  </div>
                </div>
                <Element className="content-div scrollable-div" id="scrollable-div">
                  <div className={'sticky-buttons scroll-back-button down'} data-testid="mobile-view-buttons">
                    <div className="back-button">
                      <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} variant={'soft-action'} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} buttonWidth={'full'} onButtonClick={handleBackButton} />
                    </div>
                    {((userDetails && appSettings?.showShareButton) || !userDetails) && <ShareButtonComponent />}
                  </div>
                  <div className="sticky-container-div">
                    <div className="sticky-div">
                      {scrollDirection === 'up' && (
                        <div className="sub-sticky-div">
                          <div className="sticky-icons-container" data-testid="news-dummy-date-while-api-call">
                            <i className="pi pi-calendar social-feed-icons calendar-clock-icons" />
                            {t('DUMMY_NEWS_DATE')}
                          </div>
                          <div className="sticky-icons-container" data-testid="news-dummy-time-while-api-call">
                            <i className="pi pi-clock social-feed-icons calendar-clock-icons" />
                            {t('DUMMY_NEWS_TIME')}
                          </div>
                        </div>
                      )}
                      <div className="news-heading-subject" data-testid="news-dummy-title-while-api-call">
                        {t('DUMMY_NEWS_TITLE')}
                      </div>
                      <div className="sticky-box-bottom-part"></div>
                      {scrollDirection === 'up' && (
                        <div className="sub-sticky-div">
                          <div className="account-icon-container" data-testid="news-dummy-author-while-api-call">
                            <i className="pi pi-user social-feed-icons account-icon user-account-icon" />
                            {t('DUMMY_NEWS_AUTHOR')}
                          </div>
                          <div className="right-part-icon-container">
                            <div className="view-icon-container" data-testid="news-dummy-view-while-api-call">
                              <i className="pi pi-eye social-feed-icons view-icon" /> {views}
                            </div>

                            <div className="heart-icon" data-testid="news-dummy-heart-svg-while-api-call">
                              <UnFilledHeartIcon className="heart-logo-blob" fill={'red'} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="carousel-for-news hideCarosel">
                    <div className="carousel-container">
                      <img alt="" src="https://www.coffeeweb.com/CoffeeWeb_Logo_White_Background_Blue_Text.png" title="default-image-mobile-view-while-api-call" className="assets" />
                    </div>
                  </div>

                  <div>
                    <div className="news-html"></div>
                  </div>
                  <div className="news-html-full-stop">.</div>
                </Element>
              </React.Fragment>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NewsReadMore
