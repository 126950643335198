import './styles.scss'
import React from 'react'

const ImagePreview = ({ mediaList, setShowImagePreview, imagePreviewActiveIndex, setImagePreviewActiveIndex }) => {
  const imageSliceHandler = (index, type, e) => {
    if (mediaList?.length) {
      const lastIndex = mediaList.length - 1

      if (type === 'right') {
        setImagePreviewActiveIndex((index + 1) % mediaList.length)
      } else {
        setImagePreviewActiveIndex(index === 0 ? lastIndex : index - 1)
      }

      e.stopPropagation()
    }
  }

  return (
    <div>
      <div className="image_preview_component" data-testid="image-preview-component" onClick={() => setShowImagePreview(false)}>
        {mediaList?.map((ele, index) => (
          <React.Fragment key={index}>
            {imagePreviewActiveIndex === index && (
              <div className="media_wrapper" data-testid="media-wrapper">
                <div>{mediaList?.length !== 1 ? <i className="pi pi-chevron-left" data-testid="chevron-left-icon" onClick={(e) => imageSliceHandler(index, 'left', e)}></i> : ''}</div>
                <div className="media-section" data-testid="media-section">
                  {ele?.typeMedia === 'image' ? <img src={ele?.pathOfMedia} alt="image_preview" onClick={(e) => e.stopPropagation()} /> : <video src={ele?.pathOfMedia} controls autoPlay></video>}
                </div>
                <div>{mediaList?.length !== 1 ? <i className="pi pi-chevron-right" data-testid="chevron-right-icon" onClick={(e) => imageSliceHandler(index, 'right', e)}></i> : ''}</div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default ImagePreview
