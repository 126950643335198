import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const DialogModal = (props) => {
  const { t } = useTranslation()
  const { modalType, showModal, message, onRetry, onDismiss, onHide, multipleMessage, mockShowDialog } = props

  const [modalState, setModalState] = useState({
    showDialog: showModal,
    error: 'ERROR'
  })

  const actionButtons = {
    softAction: props?.softAction ? props?.softAction : 'DISMISS',
    hardAction: props?.hardAction ? props?.hardAction : 'RETRY'
  }

  useEffect(() => {
    setModalState({ ...modalState, showDialog: showModal })
  }, [showModal])

  // testing purpose
  useEffect(() => {
    if (mockShowDialog) {
      setModalState((prev) => ({
        ...prev,
        showDialog: true
      }))
    }
  }, [])

  const ErrorButtons = () => (
    <div className="dialog-component-footer" data-testid="dialog-component-error-buttons">
      <Button label={t(actionButtons.softAction).toUpperCase()} onClick={() => onDismiss()} className="neutral-button" data-testid="neutral-button" />
      {onRetry && <Button label={t(actionButtons.hardAction).toUpperCase()} onClick={() => onRetry()} className="retry-button" data-testid="retry-button" />}
    </div>
  )

  const FooterMessage = () => (
    <>
      {multipleMessage ? (
        <>
          <div className="dialog-component-message" data-testid="dialog-component-message">
            {t(message)}
          </div>
          <div>
            <div data-testid="first-message">{t(multipleMessage.firstMessage)}</div>
            <div data-testid="second-message">{t(multipleMessage.secondMessage)}</div>
          </div>
        </>
      ) : (
        <div>
          {props.headerMessage ? <div className="custom-message-1">{props.headerMessage}</div> : <div>{t(message)}</div>}
          {props.customMessage && <div className="custom-message-2">{props.customMessage}</div>}
        </div>
      )}
    </>
  )

  return (
    <div>
      <Dialog visible={modalState.showDialog} onHide={() => onHide()} data-testid="dialog-component" className="dialog-component" header={<span className={'dialog-component-error'}>{t(modalState[modalType])}</span>} footer={<ErrorButtons />}>
        <FooterMessage />
      </Dialog>
    </div>
  )
}

export default DialogModal
