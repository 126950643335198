import React from 'react'
import PortStockReports from './PortStockReports'
import CertifiedStockReports from './TotalCertifiedStocks'
import CountryCertifiedStocks from './CountryStockReports'

const CertifiedStockReport = ({ activeTab, marketName, selectedMarket, setSelectedMarket, testingId }) => (
  <div className="coffee-report-table" data-testid={testingId}>
    {activeTab === 'tab1' && <CertifiedStockReports marketName={marketName} selectedMarket={selectedMarket} setSelectedMarket={setSelectedMarket} />}
    {activeTab === 'tab2' && <CountryCertifiedStocks marketName={marketName} selectedMarket={selectedMarket} setSelectedMarket={setSelectedMarket} />}
    {activeTab === 'tab3' && <PortStockReports marketName={marketName} selectedMarket={selectedMarket} setSelectedMarket={setSelectedMarket} />}
  </div>
)

export default CertifiedStockReport
