import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import { popupIcon } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'

export default function India({ swapReport, setLoading, mockFetchedIndiaData, mockShowModal, createMonthRange, checkForUserAccess }) {
  const { t } = useTranslation()
  const toast = useRef(null)
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }
  const { indiaCountryWiseExportsReport } = coffeeReportJsonLinks

  const [fetchedData, setFetchedData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [currentYear, setCurrentYear] = useState('')
  const [previousYear, setPreviousYear] = useState('')
  const [modalType, setModalType] = useState(null)
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null)

  //  Testing Purpose
  useEffect(() => {
    setFetchedData(mockFetchedIndiaData)
    setShowModal(mockShowModal)
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const reportName = indiaCountryWiseExportsReport

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const calculateTotals = (countryCertifiedData) => {
    const totals = {
      PLAP: 0,
      AC: 0,
      RP: 0,
      RC: 0,
      INSTANT: 0,
      ROASTED: 0,
      GROUND: 0
    }

    countryCertifiedData.forEach(({ PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND }) => {
      totals.PLAP += Number(PLAP) || 0
      totals.AC += Number(AC) || 0
      totals.RP += Number(RP) || 0
      totals.RC += Number(RC) || 0
      totals.INSTANT += Number(INSTANT) || 0
      totals.ROASTED += Number(ROASTED) || 0
      totals.GROUND += Number(GROUND) || 0
    })

    return totals
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === '' || Number.isNaN(Number(valueInMT))) {
      return ''
    }

    const result = (valueInMT * 1000) / 60

    return Math.round(result) || ''
  }

  const convertToMillion = (value) => {
    if (!value || Number.isNaN(Number(value))) {
      return ''
    }

    const result = value / 60000

    return result.toFixed(4)
  }

  const formatValue = (value, isDecimal) => {
    if (value === '' || Number.isNaN(Number(value))) {
      return ''
    }

    if (isDecimal) {
      return value.toFixed(2)
    }

    return Math.round(value) || ''
  }

  const displayFormattedValue = (value, isDecimal) => {
    if (swapReport === 'Metric Tons') {
      return formatValue(value, isDecimal).toLocaleString()
    }

    if (swapReport === '60 KG Bags') {
      return convertMTToBags(value).toLocaleString()
    }

    if (swapReport === 'Millions Bags') {
      return convertToMillion(value)
    }
  }

  const calculateTotalsWithMonth = (data) => {
    const totals = {
      MONTH: '',
      PLAP: 0,
      AC: 0,
      RP: 0,
      RC: 0,
      INSTANT: 0,
      ROASTED: 0,
      GROUND: 0
    }

    data.forEach(({ PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND, MONTH }) => {
      totals.PLAP += Number(PLAP) || 0
      totals.AC += Number(AC) || 0
      totals.RP += Number(RP) || 0
      totals.RC += Number(RC) || 0
      totals.INSTANT += Number(INSTANT) || 0
      totals.ROASTED += Number(ROASTED) || 0
      totals.GROUND += Number(GROUND) || 0

      if (MONTH) {
        totals.MONTH = MONTH
      }
    })

    return totals
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    }
    if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const showMonthValueModal = (currentIndex, selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const currentYearData = filteredData?.report[currentIndex]
    const previousYearData = nextData?.report[currentIndex]

    const currentYearValue = filteredData?.year.split(' : ')[1]
    const previousYearValue = nextData?.year.split(' : ')[1]

    setCurrentData(currentYearData)
    setPreviousData(previousYearData)
    setCurrentYear(currentYearValue)
    setPreviousYear(previousYearValue)
    setModalType('month')

    if (currentYearData?.PLAP !== '' && currentYearData?.AC !== '' && previousYearData?.PLAP !== '' && previousYearData?.AC !== '') {
      setShowModal(true)
    } else {
      setShowModal(false)
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_SELECTED_YEAR'), life: 3000 })
    }
  }

  const showTotalValueModal = (selectedYear) => {
    const filteredData = fetchedData.find(({ year }) => year === selectedYear)

    const selectedIndex = fetchedData.findIndex(({ year }) => year === selectedYear)

    const nextData = selectedIndex !== -1 && selectedIndex + 1 < fetchedData.length ? fetchedData[selectedIndex + 1] : null

    if (!filteredData || !nextData) {
      toast.current.show({ severity: 'error', summary: t('NO_DATA'), detail: t('NO_DATA_FOR_PREVIOUS_YEAR'), life: 3000 })

      return
    }

    const totalValue = []

    const updateData = filteredData?.report.filter((ele) => ele.PLAP && ele.AC)

    for (let i = 0; i < updateData.length; i++) {
      const elementCopy = { ...nextData?.report[i] }

      totalValue.push(elementCopy)
    }

    if (filteredData && nextData) {
      const currentYearTotal = calculateTotalsWithMonth(updateData)
      const previousYearTotal = calculateTotalsWithMonth(totalValue)

      const currentYearValue = filteredData?.year.split(' : ')[1]
      const previousYearValue = nextData?.year.split(' : ')[1]

      setCurrentData(currentYearTotal)
      setPreviousData(previousYearTotal)

      setCurrentYear(currentYearValue)
      setPreviousYear(previousYearValue)
      setModalType('total')

      if (currentYearTotal && previousYearTotal) {
        setShowModal(true)
      }
    }
  }

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredRowIndex(null)
  }

  const comparisonTableData = () => {
    if (!currentData || !previousData) {
      return null
    }

    const sumOfCurrentReports = currentData ? Number(currentData.PLAP) + Number(currentData.AC) + Number(currentData.RP) + Number(currentData.RC) + Number(currentData.INSTANT) + Number(currentData.ROASTED) + Number(currentData.GROUND) : 0
    const sumOfPreviousReports = previousData ? Number(previousData.PLAP) + Number(previousData.AC) + Number(previousData.RP) + Number(previousData.RC) + Number(previousData.INSTANT) + Number(previousData.ROASTED) + Number(previousData.GROUND) : 0

    const difference = {
      plap: Number(currentData.PLAP) - Number(previousData.PLAP) || 0,
      ac: Number(currentData.AC) - Number(previousData.AC) || 0,
      rp: Number(currentData.RP) - Number(previousData.RP) || 0,
      rc: Number(currentData.RC) - Number(previousData.RC) || 0,
      instant: Number(currentData.INSTANT) - Number(previousData.INSTANT) || 0,
      roasted: Number(currentData.ROASTED) - Number(previousData.ROASTED) || 0,
      ground: Number(currentData.GROUND) - Number(previousData.GROUND) || 0,
      total: sumOfCurrentReports - sumOfPreviousReports
    }

    const percentageDifference = {
      plap: previousData.PLAP ? ((difference.plap / Number(previousData.PLAP)) * 100).toFixed(2) : 'N/A',
      ac: previousData.AC ? ((difference.ac / Number(previousData.AC)) * 100).toFixed(2) : 'N/A',
      rp: previousData.RP ? ((difference.rp / Number(previousData.RP)) * 100).toFixed(2) : 'N/A',
      rc: previousData.RC ? ((difference.rc / Number(previousData.RC)) * 100).toFixed(2) : 'N/A',
      instant: previousData.INSTANT ? ((difference.instant / Number(previousData.INSTANT)) * 100).toFixed(2) : 'N/A',
      roasted: previousData.ROASTED ? ((difference.roasted / Number(previousData.ROASTED)) * 100).toFixed(2) : 'N/A',
      ground: previousData.GROUND ? ((difference.ground / Number(previousData.GROUND)) * 100).toFixed(2) : 'N/A',
      total: sumOfPreviousReports ? ((difference.total / sumOfPreviousReports) * 100).toFixed(2) : 'N/A'
    }

    const currentMonth = currentData.MONTH?.split('-')[0]
    const previousMonth = previousData.MONTH?.split('-')[0]

    const currentMonthYear = `${currentMonth} - ${currentYear}`
    const previousMonthYear = previousData ? `${previousMonth} - ${previousYear}` : ''

    const dynamicTitle = modalType === 'total' ? `Upto ${currentMonthYear} To ${previousMonthYear} Comparison` : `${currentMonthYear} To ${previousMonthYear} Comparison`

    return (
      <div className="comparison-table" data-testid="comparison-table">
        <div className="content-title" data-testid="comparison-table-title">
          <h5>{dynamicTitle}</h5>
        </div>
        <div className="table-container" data-testid="comparison-table-container">
          <div className="left-table-content">
            <table>
              <thead>
                <tr>
                  <th>{t('MONTH')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="empty-table-row"></tr>
                <tr onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 0 ? 'highlight' : ''}>
                  <td data-testid="current-month-data">{modalType === 'total' ? createMonthRange(currentData.MONTH) : currentData.MONTH}</td>
                </tr>

                <tr onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 1 ? 'highlight' : ''}>
                  {modalType === 'total' ? <td data-testid="previous-month-data">{createMonthRange(previousData ? previousData.MONTH : '')}</td> : <td data-testid="previous-month-data">{previousData ? previousData.MONTH : ''}</td>}
                </tr>

                <tr className="empty-table-row"></tr>

                <tr className="difference-row">
                  <td className="table-border">{t('DIFFERENCE')}</td>
                </tr>
                <tr className="percentage-row">
                  <td className="table-border">{t('PERCENTAGE_WISE')}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="right-table-content">
            <table>
              <thead>
                <tr>
                  <th className="india-country">{t('PL/AP')}</th>
                  <th className="india-country">{t('AC')}</th>
                  <th className="india-country">{t('RP')}</th>
                  <th className="india-country">{t('RC')}</th>
                  <th className="india-country">{t('INSTANT')}</th>
                  <th className="india-country">{t('ROASTED')}</th>
                  <th className="india-country">{t('GROUND')}</th>
                  <th className="india-country">{t('TOTAL')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="empty-table-row"></tr>
                <tr onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 0 ? 'highlight' : ''}>
                  <td data-testid="comparison-table-current-PLAP-data">{displayFormattedValue(currentData.PLAP, false)}</td>
                  <td data-testid="comparison-table-current-AC-data">{displayFormattedValue(currentData.AC, false)}</td>
                  <td data-testid="comparison-table-current-RP-data">{displayFormattedValue(currentData.RP, false)}</td>
                  <td data-testid="comparison-table-current-RC-data">{displayFormattedValue(currentData.RC, false)}</td>
                  <td data-testid="comparison-table-current-INSTANT-data">{displayFormattedValue(currentData.INSTANT, false)}</td>
                  <td data-testid="comparison-table-current-ROASTED-data">{displayFormattedValue(currentData.ROASTED, true)}</td>
                  <td data-testid="comparison-table-current-GROUND-data">{displayFormattedValue(currentData.GROUND, true)}</td>
                  <td data-testid="comparison-table-current-reports-data">{displayFormattedValue(sumOfCurrentReports, false)}</td>
                </tr>
                <tr onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={hoveredRowIndex === 1 ? 'highlight' : ''}>
                  <td data-testid="comparison-table-previous-PLAP-data">{displayFormattedValue(previousData.PLAP, false)}</td>
                  <td data-testid="comparison-table-previous-AC-data">{displayFormattedValue(previousData.AC, false)}</td>
                  <td data-testid="comparison-table-previous-RP-data">{displayFormattedValue(previousData.RP, false)}</td>
                  <td data-testid="comparison-table-previous-RC-data">{displayFormattedValue(previousData.RC, false)}</td>
                  <td data-testid="comparison-table-previous-INSTANT-data">{displayFormattedValue(previousData.INSTANT, false)}</td>
                  <td data-testid="comparison-table-previous-ROASTED-data">{displayFormattedValue(previousData.ROASTED, true)}</td>
                  <td data-testid="comparison-table-previous-GROUND-data">{displayFormattedValue(previousData.GROUND, true)}</td>
                  <td data-testid="comparison-table-previous-reports-data">{displayFormattedValue(sumOfPreviousReports, false)}</td>
                </tr>
                <tr className="empty-table-row"></tr>
                <tr className="difference-row" data-testid="difference-row">
                  <td data-testid="comparison-table-difference-PLAP-data" className={`${getTextColor(difference.plap)} table-border`}>
                    {displayFormattedValue(difference.plap, false)}
                  </td>
                  <td data-testid="comparison-table-difference-AC-data" className={`${getTextColor(difference.ac)} table-border`}>
                    {displayFormattedValue(difference.ac, false)}
                  </td>
                  <td data-testid="comparison-table-difference-RP-data" className={`${getTextColor(difference.rp)} table-border`}>
                    {displayFormattedValue(difference.rp, false)}
                  </td>
                  <td data-testid="comparison-table-difference-RC-data" className={`${getTextColor(difference.rc)} table-border`}>
                    {displayFormattedValue(difference.rc, false)}
                  </td>
                  <td data-testid="comparison-table-difference-INSTANT-data" className={`${getTextColor(difference.instant)} table-border`}>
                    {displayFormattedValue(difference.instant, false)}
                  </td>
                  <td data-testid="comparison-table-difference-ROASTED-data" className={`${getTextColor(difference.roasted)} table-border`}>
                    {displayFormattedValue(difference.roasted, true)}
                  </td>
                  <td data-testid="comparison-table-difference-GROUND-data" className={`${getTextColor(difference.ground)} table-border`}>
                    {displayFormattedValue(difference.ground, true)}
                  </td>
                  <td data-testid="comparison-table-difference-TOTAL-data" className={`${getTextColor(difference.total)} table-border`}>
                    {displayFormattedValue(difference.total)}
                  </td>
                </tr>
                <tr className="percentage-row" data-testid="percentage-row">
                  <td data-testid="percentage-difference-PLAP-value" className={`${getTextColor(percentageDifference.plap)} table-border`}>
                    {percentageDifference.plap !== 'N/A' ? `${percentageDifference.plap}%` : 'N/A'}
                  </td>
                  <td data-testid="percentage-difference-AC-value" className={`${getTextColor(percentageDifference.ac)} table-border`}>
                    {percentageDifference.ac !== 'N/A' ? `${percentageDifference.ac}%` : 'N/A'}
                  </td>
                  <td data-testid="percentage-difference-RP-value" className={`${getTextColor(percentageDifference.rp)} table-border`}>
                    {percentageDifference.rp !== 'N/A' ? `${percentageDifference.rp}%` : 'N/A'}
                  </td>
                  <td data-testid="percentage-difference-RC-value" className={`${getTextColor(percentageDifference.rc)} table-border`}>
                    {percentageDifference.rc !== 'N/A' ? `${percentageDifference.rc}%` : 'N/A'}
                  </td>
                  <td data-testid="percentage-difference-INSTANT-value" className={`${getTextColor(percentageDifference.instant)} table-border`}>
                    {percentageDifference.instant !== 'N/A' ? `${percentageDifference.instant}%` : 'N/A'}
                  </td>
                  <td data-testid="percentage-difference-ROASTED-value" className={`${getTextColor(percentageDifference.roasted)} table-border`}>
                    {percentageDifference.roasted !== 'N/A' ? `${percentageDifference.roasted}%` : 'N/A'}
                  </td>
                  <td data-testid="percentage-difference-GROUND-value" className={`${getTextColor(percentageDifference.ground)} table-border`}>
                    {percentageDifference.ground !== 'N/A' ? `${percentageDifference.ground}%` : 'N/A'}
                  </td>
                  <td data-testid="percentage-difference-TOTAL-value" className={`${getTextColor(percentageDifference.total)} table-border`}>
                    {percentageDifference.total !== 'N/A' ? `${percentageDifference.total}%` : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="horizontal-scroll-container" id="port-wise-stock-report" data-testid="scroll-container">
      {fetchedData?.map(({ report, year }, index) => {
        const { PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND } = calculateTotals(report)
        const sumOfTotals = PLAP + AC + RP + RC + INSTANT + ROASTED + GROUND

        return (
          <div key={index} className="table-wrapper" data-testid="table-wrapper">
            <table>
              <thead>
                <tr className="year-row" data-testid="year-row">
                  <td colSpan={12}>
                    <div data-testid="year-value">{year}</div>
                  </td>
                </tr>
                <tr>
                  <th>{t('MONTH')}</th>
                  <th>{t('PL/AP')}</th>
                  <th>{t('AC')}</th>
                  <th>{t('RP')}</th>
                  <th>{t('RC')}</th>
                  <th>{t('INSTANT')}</th>
                  <th>{t('ROASTED')}</th>
                  <th>{t('GROUND')}</th>
                  <th>{t('TOTAL')}</th>
                </tr>
                <tr className="table-row-empty"></tr>
              </thead>
              <tbody style={checkForUserAccess(menuLock)}>
                {report.map(({ MONTH, PLAP, AC, RP, RC, INSTANT, ROASTED, GROUND }, index) => {
                  const sumOfReports = PLAP + AC + RP + RC + INSTANT + ROASTED + GROUND

                  return (
                    <tr key={index}>
                      <td>
                        <div className="popup-wrapper" onClick={() => userDetails && !menuLock && showMonthValueModal(index, year)} data-testid="popup-wrapper">
                          <div data-testid="month-value">{MONTH}</div>
                          <div className="icon-wrapper" data-testid="icon-wrapper">
                            <img src={popupIcon} alt={MONTH} className="popup-icon" />
                          </div>
                        </div>
                      </td>
                      <td data-testid="PLAP-value">{displayFormattedValue(PLAP, false)}</td>
                      <td data-testid="AC-value">{displayFormattedValue(AC, false)}</td>
                      <td data-testid="RP-value">{displayFormattedValue(RP, false)}</td>
                      <td data-testid="RC-value">{displayFormattedValue(RC, false)}</td>
                      <td data-testid="INSTANT-value">{displayFormattedValue(INSTANT, false)}</td>
                      <td data-testid="ROASTED-value">{displayFormattedValue(ROASTED, true)}</td>
                      <td data-testid="GROUND-value">{displayFormattedValue(GROUND, true)}</td>
                      <td data-testid="sumOfReports-value">{displayFormattedValue(sumOfReports, false)}</td>
                    </tr>
                  )
                })}
                <tr className="no-data-row"></tr>

                <tr className="tr-color">
                  <td>
                    <div className="popup-wrapper" onClick={() => userDetails && !menuLock && showTotalValueModal(year)}>
                      <div>{t('TOTAL')}</div>
                      <div className="icon-wrapper">
                        <img src={popupIcon} alt={'icon'} className="popup-icon" />
                      </div>
                    </div>
                  </td>

                  <td>{displayFormattedValue(PLAP, false)}</td>
                  <td>{displayFormattedValue(AC, false)}</td>
                  <td>{displayFormattedValue(RP, false)}</td>
                  <td>{displayFormattedValue(RC, false)}</td>
                  <td>{displayFormattedValue(INSTANT, false)}</td>
                  <td>{displayFormattedValue(ROASTED, true)}</td>
                  <td>{displayFormattedValue(GROUND, true)}</td>
                  <td>{displayFormattedValue(sumOfTotals, false)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}
      <Toast ref={toast} position="top-right" />

      <DialogUIModal title={modalType === 'total' ? t('TOTAL_COMPARISON_DATA') : t('MONTH_COMPARISON_DATA')} showModal={showModal} handleClose={() => setShowModal(false)} bodyContent={comparisonTableData} titlePosition="center" size="lg" />
    </div>
  )
}
