import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { ChevronDownIcon } from 'primereact/icons/chevrondown'
import { ChevronUpIcon } from 'primereact/icons/chevronup'

const DropdownComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const { handleSelectCountry, countryList, setCurrentItem, setLanguageItem, isLanguageDropdown, currentItem, width, height, isGlobalDifferential, isAboutCoffeeWeb, isAuthScreen, isGuestUserPage, errorState, isNewsFeedPage, testId, disableDropdown, coffeeReportDropDown, marketDataDropdown, marketTypeDropdown, countryWiseWExportDropdown, testingId } = props

  const handleChange = ({ value }) => {
    const selectedCountry = countryList.find(({ id }) => id === value?.id)

    if (!selectedCountry || !value) {
      return
    }

    if (isAboutCoffeeWeb) {
      handleSelectCountry(value)
    } else if (isAuthScreen) {
      handleSelectCountry(selectedCountry)
      setCurrentItem(selectedCountry)
    } else if (isLanguageDropdown) {
      handleSelectCountry(value)
      setLanguageItem(value)
    } else if (marketDataDropdown) {
      handleSelectCountry(value)
      setCurrentItem(value)
    } else if (marketTypeDropdown) {
      handleSelectCountry(value)
      setCurrentItem(value)
    } else if (countryWiseWExportDropdown) {
      handleSelectCountry(selectedCountry)
      setCurrentItem(selectedCountry)
    } else {
      handleSelectCountry(selectedCountry)
      setCurrentItem(selectedCountry)
    }
  }

  const contentToRender = (option) => {
    if (isAboutCoffeeWeb) {
      return option?.countryName
    }
    if (isLanguageDropdown) {
      return option?.languageName
    }
    if (coffeeReportDropDown) {
      return option?.report
    }
    if (marketDataDropdown) {
      return option?.marketName
    }
    if (marketTypeDropdown) {
      return option?.marketName
    }
    if (countryWiseWExportDropdown) {
      return option?.countryName
    }

    return option?.nicename
  }

  const panelStyle = () => {
    if (isAboutCoffeeWeb) {
      return 'about-panel-style'
    }
    if (isGlobalDifferential) {
      return 'global-diff-panel-style'
    }

    if (isAuthScreen) {
      return 'auth-screen-panel-style'
    }

    if (isGuestUserPage) {
      return 'guest-user-panel-style'
    }

    if (isNewsFeedPage) {
      return 'news-feed-panel-style'
    }
    if (marketDataDropdown) {
      return 'market-type-dropdown-panel'
    }
    if (marketTypeDropdown) {
      return 'coffee-reports-panel-style'
    }

    return 'dropdown-panel-style'
  }

  const filterCountries = () => {
    if (isAboutCoffeeWeb) {
      return 'countryName'
    }
    if (isLanguageDropdown) {
      return 'languageName'
    }

    return 'nicename'
  }

  const countryOptionTemplate = (option) => (
    <div className={`common-style-for-template ${isAboutCoffeeWeb ? 'dropdown-about-us' : 'dropdown-selected-item-style'} `}>
      {coffeeReportDropDown || marketDataDropdown || marketTypeDropdown ? '' : <img alt={option.name} src={isAboutCoffeeWeb ? option?.flagURL : option?.flagsUrl} className="option-country-flag" />}
      <div className={`${isAboutCoffeeWeb ? 'option-text' : ''} ${coffeeReportDropDown ? 'panel-text' : ''}`}>{contentToRender(option)}</div>
    </div>
  )

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className={`common-style-for-template ${isAboutCoffeeWeb ? 'dropdown-about-us' : ''} `}>
          {coffeeReportDropDown || marketDataDropdown || marketTypeDropdown ? '' : <img alt={option.name} src={isAboutCoffeeWeb ? option?.flagURL : option?.flagsUrl} className="selected-country-flag" />}
          <div className={`${isAboutCoffeeWeb ? 'option-text option-text-width' : ''} ${isAuthScreen ? 'iSigUp-option-text' : ''} ${coffeeReportDropDown ? 'coffee-report-text' : ''}`}>{contentToRender(option)}</div>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const emptyMessage = errorState && t('NO_DATA_FOUND')

  const placeholderText = <div className="placeholder-container">{errorState ? <div className="placeholder-text error">{t('NO_DATA_FOUND')}</div> : <div className={`placeholder-text ${isLanguageDropdown ? 'language' : 'country'} ${isAboutCoffeeWeb ? 'coffee-web' : ''}`}>{isLanguageDropdown ? t('SELECT_LANGUAGE') : t('SELECT_COUNTRY')}</div>}</div>

  const handlePanelShow = () => {
    const panel = document.querySelector('.p-dropdown-items-wrapper')

    if (panel) {
      panel.scrollTop = 0
    }
  }

  return (
    <>
      <div className={isGuestUserPage ? 'guest-user' : 'dropdown-container'} data-testid={testingId}>
        <Dropdown value={currentItem} onChange={handleChange} valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} options={countryList} optionLabel={isAboutCoffeeWeb ? 'countryName' : 'nicename'} placeholder={placeholderText} panelClassName={panelStyle()} filter={!(coffeeReportDropDown || marketTypeDropdown || countryWiseWExportDropdown)} disabled={disableDropdown} style={{ width, height }} className={isAboutCoffeeWeb ? '' : 'placeholder-text'} emptyMessage={emptyMessage} dropdownIcon={(opts) => (opts.iconProps['data-pr-overlay-visible'] ? <ChevronUpIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />)} resetFilterOnHide={true} filterBy={filterCountries()} onShow={isGlobalDifferential && handlePanelShow} emptyFilterMessage={t('NO_DATA_FOUND')} data-testId={testId} />
        <div className="error-message">{errorState && t('COUNTRY_LIST_DATA_NOT_AVAILABLE')}</div>{' '}
      </div>
    </>
  )
}

export default DropdownComponent
