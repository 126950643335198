import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { commonMethods } from 'Utils/commonMethods'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

const TotalCertifiedStocks = ({ selectedMarket, setSelectedMarket, mockTotalCertifiedStocksData }) => {
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  const { robustaTotalCertifiedStocks, arabicaTotalCertifiedStocks } = coffeeReportJsonLinks

  const { checkForUserAccess, dateFormatBasedOnUser } = commonMethods

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [swapReport, setSwapReport] = useState('Metric Tons')
  const [fetchedData, setFetchedData] = useState([])

  useEffect(() => {
    setFetchedData([])
    fetchData()
    if (selectedMarket) {
      setSwapReport('Metric Tons')
    }
  }, [selectedMarket])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockTotalCertifiedStocksData) {
      setFetchedData(mockTotalCertifiedStocksData)
    }
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const reportName = selectedMarket === 'Robusta' ? robustaTotalCertifiedStocks : arabicaTotalCertifiedStocks

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response?.data)
    } finally {
      setLoading(false)
    }
  }

  const toggleSwapOption = () => {
    setSwapReport((prevOption) => {
      if (selectedMarket === 'Robusta') {
        return prevOption === 'Metric Tons' ? 'LOTS' : 'Metric Tons'
      }
      if (selectedMarket === 'Arabica') {
        return prevOption === 'Metric Tons' ? '60 KG BAGS' : 'Metric Tons'
      }

      return 'Metric Tons'
    })
  }

  const convertMTToBags = (valueInMT) => {
    const result = (valueInMT * 1000) / 60

    return Math.round(result)
  }

  const determineCellClass = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return 'positive'
    }
    if (currentValue < previousValue) {
      return 'negative'
    }

    return 'neutral'
  }

  const determineTextColor = (differenceFromPreviousDay) => {
    if (differenceFromPreviousDay === 0) {
      return 'neutral'
    }
    if (differenceFromPreviousDay > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const formatMarketData = (value) => {
    if (selectedMarket === 'Robusta') {
      const formattedRobustaData = swapReport === 'Metric Tons' ? value?.toLocaleString('en-US') : (value / 10)?.toLocaleString('en-US')

      return formattedRobustaData
    }

    const formattedArabicaData = swapReport === 'Metric Tons' ? Math.round(value)?.toLocaleString('en-US') : convertMTToBags(value)?.toLocaleString('en-US')

    return formattedArabicaData
  }

  const convertValuesInString = (value) => {
    const regex = /\d+(\.\d+)?/g
    const matches = value?.match(regex)

    if (matches) {
      const convertedMatches = matches.map((value) => convertMTToBags(parseFloat(value)))

      return value.replace(regex, () => convertedMatches.shift())
    }

    return value
  }

  const convertValues = (value) => {
    if (value) {
      const regex = /([A-Z\s]+(?:\([\w\s]+\))?)\s*-\s*(\d+(\.\d+)?)/g

      const roundedParts = value.replace(regex, (match, label, num) => {
        const parsedNum = parseFloat(num)

        if (!Number.isNaN(parsedNum)) {
          const roundedNum = Math.round(parsedNum)

          return `${label} - ${roundedNum}`
        }

        return label
      })

      return roundedParts
    }
  }

  const transformData = (data) => {
    const response = data?.details?.map((detail, detailIndex) => {
      const previousCertifiedStocks = data?.details[detailIndex + 1]?.certifiedStocks || data?.details?.certifiedStocks
      const certifiedStockColor = determineCellClass(detail?.certifiedStocks, previousCertifiedStocks)
      const compareToPreviousDayColor = determineTextColor(detail?.ComparedToPreDay)

      const formattedCertifiedStocks = formatMarketData(detail?.certifiedStocks)
      const formattedComparisonToPreviousDay = formatMarketData(detail?.ComparedToPreDay)

      const result = {
        ...detail,
        certifiedStockColor,
        formattedCertifiedStocks,
        formattedComparisonToPreviousDay,
        compareToPreviousDayColor
      }

      if (selectedMarket === 'Robusta') {
        const formattedNonTenderable = formatMarketData(detail?.nonTenderable)
        const formattedSuspended = formatMarketData(detail?.suspended)

        return {
          ...result,
          formattedNonTenderable,
          formattedSuspended
        }
      }

      let formattedPendingGradingReport = detail?.PendingGradingReport
      let formattedGrading = detail?.grading

      if (selectedMarket === 'Arabica') {
        if (swapReport !== 'Metric Tons') {
          formattedPendingGradingReport = convertValuesInString(detail?.PendingGradingReport)
          formattedGrading = convertValuesInString(detail?.grading)
        } else {
          formattedPendingGradingReport = convertValues(detail?.PendingGradingReport)
          formattedGrading = convertValues(detail?.grading)
        }

        return {
          ...result,
          formattedPendingGradingReport,
          formattedGrading
        }
      }

      return result
    })

    return response
  }

  return (
    <div className="certified-reports-wrapper" data-testid="certified-reports-wrapper">
      <div className="header-container-wrapper" data-testid="certified-reports-header-wrapper">
        <div className="header-container" data-testid="certified-reports-header-container">
          <div className="market-selector" onClick={() => setSelectedMarket(selectedMarket === 'Robusta' ? 'Arabica' : 'Robusta')} data-testid="contract-label-container">
            {selectedMarket}
            <div className="arrow-toggle" data-testid="contract-arrow-toggle-container">
              <i className="pi pi-angle-up arrow-up" data-testid="arrow-up-icon" />
              <i className="pi pi-angle-down arrow-down" data-testid="arrow-down-icon" />
            </div>
          </div>

          <div className="content-title">{t('CERTIFIED_STOCKS_REPORTS')}</div>

          <div className="swap-content-wrapper" onClick={toggleSwapOption} data-testid="swap-content-wrapper">
            {swapReport}
            <div className="swap-button" data-testid="quantity-swap-button">
              <i className="pi pi-angle-up upper-arrow" data-testid="quantity-arrow-up-icon" />
              <i className="pi pi-angle-down down-arrow" data-testid="quantity-arrow-down-icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="table-container" data-testid="total-certified-report-table-container">
        <table>
          <thead>
            <tr>
              <th className="header-report-date">{t('REPORT_DATE')}</th>
              <th className="header-certified-stocks">{t('CERTIFIED_STOCKS')}</th>
              <th className="header-compare-to-previous-day">{t('COMPARE_TO_PRE_DAY')}</th>
              {selectedMarket === 'Robusta' ? (
                <>
                  <th className="non-tenderable">{t('NON_TENDERABLE')}</th>
                  <th className="suspended">{t('SUSPENDED')}</th>
                </>
              ) : (
                <>
                  <th className="header-pending-grading-report">{t('PENDING_GRADING_REPORT')}</th>
                  <th className="daily-grading-report">{t('DAILY_GRADING_SUMMARY')}</th>
                </>
              )}
            </tr>
            <tr className="empty-row"></tr>
          </thead>

          <tbody style={checkForUserAccess(menuLock)}>
            {selectedMarket === 'Robusta' &&
              transformData(fetchedData)?.map(({ date, compareToPreviousDayColor, certifiedStockColor, formattedCertifiedStocks, formattedComparisonToPreviousDay, formattedNonTenderable, formattedSuspended }, index) => (
                <tr key={index}>
                  <td data-testid="robust-table-date">{dateFormatBasedOnUser(date)}</td>
                  <td className={certifiedStockColor} data-testid="robust-table-formatted-certified-stocks">
                    {formattedCertifiedStocks}
                  </td>
                  <td className={compareToPreviousDayColor} data-testid="robust-table-formatted-comparison-previous-day-color">
                    {formattedComparisonToPreviousDay}
                  </td>
                  <td data-testid="robust-table-formatted-non-tenderable">{formattedNonTenderable}</td>
                  <td data-testid="robust-table-formatted-suspended">{formattedSuspended}</td>
                </tr>
              ))}
            {selectedMarket === 'Arabica' &&
              transformData(fetchedData)?.map(({ Date, compareToPreviousDayColor, certifiedStockColor, formattedCertifiedStocks, formattedComparisonToPreviousDay, formattedPendingGradingReport, formattedGrading }, index) => (
                <tr key={index}>
                  <td data-testid="arabica-table-date">{dateFormatBasedOnUser(Date)}</td>
                  <td className={certifiedStockColor} data-testid="arabica-table-formatted-certified-stocks">
                    {formattedCertifiedStocks}
                  </td>
                  <td className={compareToPreviousDayColor} data-testid="arabica-table-formatted-comparison-previous-day-color">
                    {formattedComparisonToPreviousDay}
                  </td>
                  <td data-testid="arabica-table-formatted-pending-grading-report">{formattedPendingGradingReport}</td>
                  <td data-testid="arabica-table-formatted-grading">{formattedGrading}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TotalCertifiedStocks
