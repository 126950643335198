import './styles.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import Button from 'Components/UIComponent/Button'
import ReactHtmlParser from 'react-html-parser'

const ConfirmationModal = ({ type, title, message, buttonLabel, onConfirm, onCancel, closeModal, dismissButtonLabel, statusLogo, buttonAction }) => {
  const { t } = useTranslation()

  const headerTemplate = () => (
    <div className="header-content" data-testid="header-content">
      <div className={`header-title ${type}`} data-testid="header-title">
        {title}
      </div>
      <div className="header-close-button" data-testid="header-close-button">
        <div className="close-button-wrapper">
          <i className="pi pi-times-circle close-icon" onClick={onCancel || closeModal}></i>
        </div>
      </div>
    </div>
  )

  return (
    <div className="modal-container">
      <Dialog
        header={headerTemplate}
        visible={true}
        className={`custom-modal ${type}`}
        closable={false}
        footer={
          <div className="modal-buttons-wrapper" data-testid="modal-button-wrapper">
            {type === 'confirmation' && <Button buttonType="CUSTOM" buttonLabel={dismissButtonLabel} variant="soft-action" onButtonClick={onCancel} />}

            <Button buttonLabel={buttonLabel || (onCancel ? t('CONFIRM') : t('OK'))} buttonType="CUSTOM" onButtonClick={onConfirm} variant={buttonAction ?? 'hard-action'} />
          </div>
        }
      >
        <div className="modal-message-wrapper" data-testid="modal-status-logo-wrapper">
          {statusLogo && (
            <div className="modal-logo">
              <img src={statusLogo} alt="modal logo" className="modal-icon" />
            </div>
          )}
          <div className="modal-message-text" data-testid="modal-message-text">
            {ReactHtmlParser(message)}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

const ErrorMessageModal = (WrappedComponent) => {
  const EnhancedComponent = (props) => {
    const { t } = useTranslation()

    const [modalProps, setModalProps] = useState(null)

    const showModal = ({ type, title, message, buttonLabel, onConfirm, onCancel, dismissButtonLabel, statusLogo, buttonAction }) => setModalProps({ type, title, message, buttonLabel, onConfirm, onCancel, dismissButtonLabel, statusLogo, buttonAction })

    const closeModal = () => setModalProps(null)

    const showSuccess = ({ title, message, buttonLabel, statusLogo, buttonAction, onConfirm, onCancel }) => {
      showModal({
        type: 'success',
        title: title || t('SUCCESS'),
        message: message || t('DEFAULT_SUCCESS_MESSAGE'),
        buttonLabel: buttonLabel || t('OK'),
        statusLogo,
        buttonAction,
        onConfirm: () => {
          closeModal()
          if (onConfirm) onConfirm()
        },
        onCancel: () => {
          closeModal()
          if (onCancel) onCancel()
        }
      })
    }

    const showError = ({ title, message, buttonLabel, statusLogo, buttonAction, onConfirm, onCancel }) => {
      showModal({
        type: 'error',
        title: title || t('ERROR_TITLE'),
        message: message || t('DEFAULT_ERROR_MESSAGE'),
        buttonLabel: buttonLabel || t('OK'),
        statusLogo,
        buttonAction,
        onConfirm: () => {
          closeModal()
          if (onConfirm) onConfirm()
        },
        onCancel: () => {
          closeModal()
          if (onCancel) onCancel()
        }
      })
    }

    const showConfirmation = ({ title, message, buttonLabel, statusLogo, onConfirm, dismissButtonLabel, onCancel, buttonAction }) => {
      showModal({
        type: 'confirmation',
        title: title || t('ARE_YOU_SURE'),
        message: message || t('DEFAULT_CONFIRMATION_MESSAGE'),
        buttonLabel: buttonLabel || t('CONFIRM'),
        statusLogo,
        buttonAction,
        onConfirm: () => {
          closeModal()
          if (onConfirm) onConfirm()
        },
        onCancel: () => {
          closeModal()
          if (onCancel) onCancel()
        },
        dismissButtonLabel: dismissButtonLabel || t('CANCEL')
      })
    }

    return (
      <>
        {modalProps && <ConfirmationModal {...modalProps} closeModal={closeModal} />}
        <WrappedComponent {...props} showSuccess={showSuccess} showError={showError} showConfirmation={showConfirmation} />
      </>
    )
  }

  return EnhancedComponent
}

export default ErrorMessageModal
