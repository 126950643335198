import './styles.scss'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import Button from 'Components/UIComponent/Button'
import { commonMethods } from 'Utils/commonMethods'

// ErrorFallback component to display when an error occurs
function ErrorFallback({ error, resetErrorBoundary }) {
  const history = useHistory()
  const { i18n, t } = useTranslation()
  const { refreshLocalStorage } = commonMethods

  if (error === 'unAuthorized') {
    setTimeout(() => {
      redirectToLogin()
    }, 5000)
  }

  const redirectToLogin = () => {
    refreshLocalStorage()
    history.push({
      pathname: ROUTE_STRINGS.guestCoffeeQuotes
    })
    resetErrorBoundary()
  }

  return (
    <>
      {error === 'unAuthorized' ? (
        <div className="session-expired-wrapper" data-testid="session-expired-wrapper">
          <div className="session-expired-container">
            <div className="info">
              <h3>{t('YOUR_SESSION_HAS_EXPIRED')}</h3>
              <p>{t('PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING_THE_APP')}</p>
            </div>
            <div className="session-expired-login-button">
              <Button buttonType={'CUSTOM'} buttonLabel={t('RE_LOGIN')} />
            </div>
          </div>
        </div>
      ) : (
        <div className="error-boundary-container" data-testid="error-boundary-container">
          <h2>{t('SOMETHING_WENT_WRONG')}</h2>
          <p data-testid="error-status">{error.status}</p>
          <p data-testid="error-title">{error.title}</p>
          <Button buttonType={'CUSTOM'} buttonLabel={t('TRY_AGAIN')} variant={'danger-action'} onButtonClick={resetErrorBoundary} />
        </div>
      )}
    </>
  )
}
// Wrapper component using ErrorBoundary
function MyErrorBoundary({ children }) {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
}
export default MyErrorBoundary
