import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalLoggedInStatus, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import DropdownComponent from 'Components/DropdownComponent'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import Brazil from './CountryWiseData/Brazil'
import Vietnam from './CountryWiseData/Vietnam'
import Uganda from './CountryWiseData/Uganda'
import Columbia from './CountryWiseData/Columbia'
import India from './CountryWiseData/India'

const CountryWiseExportsReport = ({ testingId }) => {
  const { t } = useTranslation()
  const { checkForUserAccess } = commonMethods
  const { setLoading } = useContext(CoffeeWebContext)

  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const isUserLoggedIn = getLocalLoggedInStatus()
  const userDetails = getLocalUserDetails()

  const [currentItem, setCurrentItem] = useState(null)
  const [swapReport, setSwapReport] = useState('Metric Tons')

  useEffect(() => {
    setSwapReport('Metric Tons')
  }, [currentItem])

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const countryLists = [
    {
      id: 1,
      countryName: 'Brazil',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BRA.svg'
    },
    {
      id: 2,
      countryName: 'Vietnam',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/VNM.svg'
    },
    {
      id: 3,
      countryName: 'Colombia',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/COL.svg'
    },
    {
      id: 4,
      countryName: 'Uganda',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/UGA.svg'
    },
    {
      id: 5,
      countryName: 'India',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/IND.svg'
    }
  ]

  const toggleSwapOption = () => {
    setSwapReport((prevOption) => {
      if (prevOption === 'Metric Tons') return '60 KG Bags'
      if (prevOption === '60 KG Bags') return 'Millions Bags'

      return 'Metric Tons'
    })
  }

  const createMonthRange = (lastMonth) => {
    const monthAbbreviations = {
      January: 'Jan',
      February: 'Feb',
      March: 'Mar',
      April: 'Apr',
      May: 'May',
      June: 'Jun',
      July: 'Jul',
      August: 'Aug',
      September: 'Sept',
      October: 'Oct',
      November: 'Nov',
      December: 'Dec'
    }

    const month = lastMonth?.split(' ')[0]
    const year = lastMonth?.split(' ')[2]

    if (!month || !year || !monthAbbreviations[month]) {
      return 'Invalid month format'
    }

    const abbreviatedMonth = monthAbbreviations[month]

    return `Upto Jan - ${year} to ${abbreviatedMonth} - ${year}`
  }

  const countryComponents = {
    Brazil: <Brazil swapReport={swapReport} setLoading={setLoading} createMonthRange={createMonthRange} checkForUserAccess={checkForUserAccess} />,
    India: <India swapReport={swapReport} setLoading={setLoading} createMonthRange={createMonthRange} checkForUserAccess={checkForUserAccess} />,
    Vietnam: <Vietnam swapReport={swapReport} setLoading={setLoading} createMonthRange={createMonthRange} checkForUserAccess={checkForUserAccess} />,
    Colombia: <Columbia swapReport={swapReport} setLoading={setLoading} createMonthRange={createMonthRange} checkForUserAccess={checkForUserAccess} />,
    Uganda: <Uganda swapReport={swapReport} setLoading={setLoading} createMonthRange={createMonthRange} checkForUserAccess={checkForUserAccess} />
  }

  useEffect(() => {
    const defaultCountry = countryLists[0]

    setCurrentItem(defaultCountry)
  }, [])

  const handleCountryChange = (selectedCountry) => {
    setCurrentItem(selectedCountry)
  }

  return (
    <>
      <div className="country-wise-exports-container" data-testid={testingId}>
        <div className="header-section" data-testid="header-section-container">
          <div className="export-header" data-testid="export-header-container">
            <div className="dropdown-wrapper" data-testid="dropdown-wrapper-container">
              <DropdownComponent handleSelectCountry={handleCountryChange} countryList={countryLists} currentItem={currentItem} setCurrentItem={setCurrentItem} placeholderText={countryLists.length ? t('SELECT_COUNTRY') : t('NO_DATA_FOUND')} countryWiseWExportDropdown={true} height="41px" testingId="country-wise-report-dropdown" />
            </div>

            <div className="report-title">{t('MONTH_WISE_REPORT')}</div>

            <div className="swap-icon-container" onClick={toggleSwapOption} data-testid="swap-icon-container">
              {swapReport}
              <div className="swap-icon" data-testid="icon-container">
                <i className="pi pi-angle-up upper-arrow" data-testid="upper-arrow-container" />
                <i className="pi pi-angle-down down-arrow" data-testid="down-arrow-container" />
              </div>
            </div>
          </div>
        </div>
        <div id="country-wise-export-report" data-testid="report-table">
          {currentItem && countryComponents[currentItem.countryName]}
        </div>
      </div>
    </>
  )
}

export default CountryWiseExportsReport
