import './styles.scss'
import React, { useRef, useContext } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import LotWiseComparison from './LotWiseComparison'
import TraderWiseComparison from './TraderWiseComparison'
import WeeklyWiseCOTReports from './WeeklyWiseCOTReports'

const CommitmentOfTradersReport = ({ activeTab, marketName, setSelectedMarket, selectedMarket, testingId }) => {
  const { t } = useTranslation()
  const { checkForUserAccess } = commonMethods
  const { setLoading } = useContext(CoffeeWebContext)
  // const location = useLocation()
  // const fullPath = location.pathname
  // const pathSegments = fullPath.split('/')
  // const desiredPath = pathSegments[2] || ''
  // const userDetails = getLocalUserDetails()

  const tabBasedDetails = {
    tab1: {
      className: 'lot-wise-report-container',
      reportName: 'LOTWISE_COMPARISON',
      blurredTab: 'lot-wise-content'
    },
    tab2: {
      className: 'weekly-wise-report-container',
      reportName: 'WEEKLY_WISE_COT_REPORTS',
      blurredTab: 'weekly-wise-content'
    },
    tab3: {
      className: 'trader-wise-report-container',
      reportName: 'TRADERWISE_COMPARISON',
      blurredTab: 'trader-wise-content'
    }
  }

  // let menuLock

  // if (userDetails && userDetails.menus) {
  //   const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

  //   if (menu) {
  //     menuLock = menu.menulock
  //   }
  // }

  const leftDivRef = useRef(null)
  const rightDivRef = useRef(null)

  const scrollFunction = () => {
    const leftDiv = leftDivRef.current
    const rightDiv = rightDivRef.current

    const handleScroll = (e) => {
      if (e.target === leftDiv) {
        rightDiv.scrollTop = leftDiv.scrollTop
      } else if (e.target === rightDiv) {
        leftDiv.scrollTop = rightDiv.scrollTop
      }
    }

    leftDiv.addEventListener('scroll', handleScroll)
    rightDiv.addEventListener('scroll', handleScroll)

    return () => {
      leftDiv.removeEventListener('scroll', handleScroll)
      rightDiv.removeEventListener('scroll', handleScroll)
    }
  }

  const handleMarketNameChange = () => {
    setSelectedMarket(selectedMarket === 'Robusta' ? 'Arabica' : 'Robusta')
  }

  const props = {
    leftDivRef,
    rightDivRef,
    scrollFunction,
    marketName,
    selectedMarket,
    setLoading
  }

  return (
    <>
      <div className={`coffee-report-table ${tabBasedDetails[activeTab].className}`} data-testid={testingId}>
        <div data-testid="commitment-of-traders-table">
          <div className="table-heading" data-testid="table-heading">
            <div className="market-type-dropdown" data-testid="market-type-drop-down">
              <div className="market-selector" onClick={handleMarketNameChange} data-testid="market-type-selector">
                {selectedMarket}

                <div className="arrow-toggle" data-testid="arrow-toggle">
                  <i className="pi pi-angle-up arrow-up" data-testid="arrow-up-icon" />
                  <i className="pi pi-angle-down arrow-down" data-testid="arrow-down-icon" />
                </div>
              </div>
            </div>
            <div className="report-name" data-testid="report-name">
              {t(tabBasedDetails[activeTab].reportName)}
            </div>
          </div>
        </div>

        {activeTab === 'tab1' && <LotWiseComparison {...props} />}
        {activeTab === 'tab2' && <WeeklyWiseCOTReports {...props} />}
        {activeTab === 'tab3' && <TraderWiseComparison {...props} />}
      </div>
    </>
  )
}

export default CommitmentOfTradersReport
